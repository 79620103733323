import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';

type Props = {
    finishedLoadingVarslinger: boolean;
    showEmptyState: boolean;
};

const VarslingMenyEmptyState = ({ finishedLoadingVarslinger: isLoadingVarslinger, showEmptyState }: Props) => {
    let p = usePhrases('meny');

    return isLoadingVarslinger && showEmptyState ? (
        <ListItem sx={{ padding: 0, borderBottom: 1, borderBottomColor: '#d3d3d3' }}>
            <ListItemAvatar sx={{ padding: '15px' }}>
                <img
                    style={{ width: '6.25rem' }}
                    src={require('../../static/images/empty_state.webp')}
                    alt={'empty state'}
                />
            </ListItemAvatar>
            <ListItemText
                sx={{ padding: '0px 45px 0px 0px' }}
                primary={
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            margin: '1',
                        }}
                    >
                        <Typography sx={{ textAlign: 'center' }}>{p('you_have_no_notifications')}</Typography>
                    </Typography>
                }
            />
        </ListItem>
    ) : null;
};

export default VarslingMenyEmptyState;
