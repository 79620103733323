import * as React from 'react';
import { ApiMe } from '../../helpers/types';
import { Kunngjoringer, UserAccessType } from './KunngjoringsMelding';
import NotificationWrapper from './NotificationWrapper';

type Props = {
    kunngjoringer: Kunngjoringer;
    setKunngjoringer: React.Dispatch<React.SetStateAction<Kunngjoringer>>;
    minInfo?: ApiMe;
};

const KunngjoringerForAllAdministratorer = ({ kunngjoringer, setKunngjoringer, minInfo }: Props) => {
    return (
        <>
            {minInfo &&
                kunngjoringer
                    ?.filter((k) => k.UserAccess === UserAccessType.Administrator && minInfo?.ErAdmin)
                    .map((k) => (
                        <NotificationWrapper
                            k={k}
                            minInfoId={minInfo.Id}
                            setKunngjoringer={setKunngjoringer}
                            key={k.Id}
                        />
                    ))}
        </>
    );
};

export default KunngjoringerForAllAdministratorer;
