import useSWR from 'swr';
import { apiVarslingerVarslingerUrl, singleItemFetch } from '../helpers/apiHelpers';
import { Varsling } from '../helpers/types';

const useVarslinger = (isOpen: boolean) => {
    const { data, isLoading } = useSWR<Varsling[]>(isOpen ? apiVarslingerVarslingerUrl : null, singleItemFetch, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateIfStale: false,
    });

    return {
        varslinger:
            data?.sort((a, b) => new Date(b.CreatedAt).getTime() - new Date(a.CreatedAt).getTime()).slice(0, 7) ?? [],
        isLoadingVarslinger: isLoading,
    };
};

export default useVarslinger;
