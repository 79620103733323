import useSWR from 'swr';
import { apiVarslingerCountUrl, singleItemFetch } from '../helpers/apiHelpers';

const useCount = () => {
    const { data } = useSWR(apiVarslingerCountUrl, singleItemFetch, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        revalidateIfStale: false,
    });

    return {
        count: data,
    };
};

export default useCount;
