import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Avatar } from '@mui/material';
import { Varsling } from '../../helpers/types';

const VarslingBadge = ({ varsling }: { varsling: Varsling }) => {
    const varslingColor = varsling.Color ? varsling.Color : '#3878c7';
    return (
        <Avatar sx={{ width: '18px', height: '18px', backgroundColor: varslingColor }}>
            <FontAwesomeIcon icon={faBell} style={{ fontSize: '14px' }} />
        </Avatar>
    );
};

export default VarslingBadge;
