import { useState } from 'react';
import { faCircle } from '@fortawesome/pro-solid-svg-icons/faCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Badge,
    ListItem,
    ListItemAvatar,
    ListItemButton,
    ListItemSecondaryAction,
    ListItemText,
    Tooltip,
    Typography,
} from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { mutate } from 'swr';
import { apiVarslingerVarslingerUrl, patchRead } from '../../helpers/apiHelpers';
import { Varsling } from '../../helpers/types';
import { calculateNotificationAge, createVarslingTooltip } from '../../helpers/varslingHelper';
import VarslingAvatar from './VarslingAvatar';
import VarslingBadge from './VarslingBadge';

type Props = {
    varsling: Varsling;
};

const VarslingEntry = ({ varsling }: Props) => {
    const [isReadLocally, setIsReadLocally] = useState(varsling.IsRead);

    const p = usePhrases('meny');

    const handleOnClick = async () => {
        // Optimistic update: Set the local state to true immediately
        setIsReadLocally(true);

        if (!varsling.IsRead) {
            // Send the request to mark as read
            const res = await patchRead([varsling.Id], true);
            if (res.ok) {
                // Update the SWR cache with the new data
                mutate(apiVarslingerVarslingerUrl, (data?: Varsling[]) => {
                    return data?.map((oldVarsling) =>
                        oldVarsling.Id === varsling.Id ? { ...oldVarsling, IsRead: true } : oldVarsling
                    );
                });
            } else {
                // Revert the local state if the request fails
                setIsReadLocally(false);
            }
        }

        // Redirect to the URL
        window.location.href = varsling.Url;
    };

    return (
        <ListItem sx={{ padding: 0, borderBottom: 1, borderBottomColor: '#d3d3d3' }}>
            <ListItemButton sx={{ padding: 0 }} onClick={handleOnClick}>
                <ListItemAvatar sx={{ padding: '15px' }}>
                    <Tooltip title={createVarslingTooltip(varsling)}>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            badgeContent={VarslingBadge({ varsling })}
                        >
                            <VarslingAvatar varsling={varsling} />
                        </Badge>
                    </Tooltip>
                </ListItemAvatar>

                <ListItemText
                    sx={{ padding: '0px 45px 0px 0px' }}
                    primary={
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '2',
                                WebkitBoxOrient: 'vertical',
                            }}
                        >
                            {varsling.Message}
                        </Typography>
                    }
                    secondary={
                        <>
                            <Typography
                                sx={{ display: 'inline' }}
                                component="span"
                                variant="body2"
                                color="text.primary"
                            >
                                {varsling.Sender.Name}
                            </Typography>
                            {' — ' + calculateNotificationAge(varsling.CreatedAt)}
                        </>
                    }
                />

                {!isReadLocally ? (
                    <ListItemSecondaryAction
                        sx={{
                            width: '2.75rem',
                            height: '2.75rem',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={async (e) => {
                            e.stopPropagation();
                            await mutate(
                                apiVarslingerVarslingerUrl,
                                async (data?: Varsling[]) => {
                                    const res = await patchRead([varsling.Id]);
                                    if (res.ok) {
                                        return data?.map((oldVarsling) =>
                                            oldVarsling.Id === varsling.Id
                                                ? { ...oldVarsling, IsRead: true }
                                                : oldVarsling
                                        );
                                    }
                                    return data;
                                },
                                {
                                    revalidate: false,
                                    rollbackOnError: true,
                                    optimisticData: (data: Varsling[]) => {
                                        return data?.map((oldVarsling) => {
                                            return oldVarsling.Id === varsling.Id
                                                ? { ...oldVarsling, IsRead: true }
                                                : oldVarsling;
                                        });
                                    },
                                }
                            );
                        }}
                    >
                        <Tooltip title={p('mark_as_read')} placement={'top-end'} arrow={true}>
                            <FontAwesomeIcon color={'#3878c7'} icon={faCircle} size={'xs'} />
                        </Tooltip>
                    </ListItemSecondaryAction>
                ) : null}
            </ListItemButton>
        </ListItem>
    );
};

export default VarslingEntry;
