import React from 'react';
import { faSearch } from '@fortawesome/pro-solid-svg-icons/faSearch';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WithPhrases } from '@tidsbanken/phrases';
import SoekTreff from './SoekTreff';
import './Search.less';

interface ISearchProps {
    sokStatusEndret: (status: boolean) => void;
}

interface ISearchState {
    soker: boolean;
    sokestreng: string;
}

export default class Search extends React.Component<ISearchProps, ISearchState> {
    private sokInput: HTMLInputElement;
    private wrapper: HTMLElement;
    private listeningOnClick: boolean = false;

    constructor(props: ISearchProps) {
        super(props);
        this.state = { soker: false, sokestreng: '' };
    }

    render() {
        return (
            <WithPhrases domain="meny">
                {(p) => (
                    <div
                        className="SearchWrapper"
                        ref={(wrapper) => {
                            if (wrapper !== null) {
                                this.wrapper = wrapper;
                            }
                        }}
                    >
                        <div
                            className={
                                'Search' +
                                (() => {
                                    return this.soker();
                                })()
                            }
                        >
                            <a
                                onClick={() => {
                                    this.toogleHide();
                                }}
                            >
                                <div className="SearchIcon">
                                    <FontAwesomeIcon icon={faSearch} aria-hidden="true" className="fa fa-search" />
                                </div>
                            </a>
                            <input
                                placeholder={p('top_search_ph')}
                                ref={(input) => {
                                    if (input !== null) {
                                        this.sokInput = input;
                                    }
                                }}
                                onChange={(e) => {
                                    this.setState({
                                        sokestreng: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <SoekTreff sokestreng={this.state.sokestreng} soker={this.state.soker} />
                    </div>
                )}
            </WithPhrases>
        );
    }

    private toogleHide() {
        this.props.sokStatusEndret(!this.state.soker);

        if (!this.listeningOnClick) {
            const eventHandler = (e: Event) => {
                if (!this.wrapper.contains(e.target as Node) && this.state.soker) {
                    this.toogleHide();
                }
            };

            ['click', 'touchstart'].map((eventName) => {
                document.addEventListener(eventName, eventHandler);
            });

            this.listeningOnClick = true;
        }

        if (!this.state.soker) {
            this.sokInput.value = '';
            this.setState({ sokestreng: '' });
            this.sokInput.disabled = false;
            this.sokInput.focus();
        } else {
            this.sokInput.disabled = true;
            this.sokInput.blur();
        }

        this.setState({ soker: !this.state.soker });
    }

    private soker(): string {
        return this.state.soker ? ' soker' : '';
    }
}
