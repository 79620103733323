import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { faQuestionSquare } from '@fortawesome/pro-solid-svg-icons/faQuestionSquare';
import { WithPhrases } from '@tidsbanken/phrases';
import { ApiMe } from '../helpers/types';
import { ErrorFallback } from './UI/ErrorFallbackBell';
import VarslingBell from './Varslinger/VarslingBell';
import Marketplace from './Marketplace';
import Search from './Search';
import TBLogo from './TBLogo';
import TestDbPanel from './TestDbPanel';
import ToppMenyBruker from './ToppMenyBruker';
import ToppMenyItem from './ToppMenyItem';
import { ToppMenyMeldinger } from './ToppMenyMeldinger';
import VisMenyKnapp from './VisMenyKnapp';
import './ToppMeny.less';

interface IToppMenyProps {
    toggleVenstreMeny: () => void;
    toggleHelp: () => void;
    visVenstreMeny: boolean;
    minInfo?: ApiMe;
}

interface IToppMenyState {
    soker: boolean;
    unread?: number;
}

class ToppMeny extends React.Component<IToppMenyProps> {
    state: IToppMenyState;

    constructor(props: IToppMenyProps) {
        super(props);

        this.state = {
            soker: false,
        };

        this.setUnread = this.setUnread.bind(this);
    }

    setUnread(num: number) {
        this.setState({ unread: num });
    }

    render() {
        return (
            <div className="TWrapper">
                <div
                    className={
                        'ToppMeny' +
                        (() => {
                            return this.hentSokKlasse();
                        })()
                    }
                >
                    <div className="ToppMenyWrapper">
                        <a href="/hjem" className="logo">
                            <TBLogo className="logo" />
                        </a>

                        {this.props.minInfo?.ErTestDatabase || this.props.minInfo?.ErMalDatabase ? (
                            <TestDbPanel minInfo={this.props.minInfo} />
                        ) : null}
                        <div className="ToppMenyRight">
                            <div className="ToppMenyRightLeft">
                                <VisMenyKnapp
                                    toggleVenstreMeny={this.props.toggleVenstreMeny}
                                    roter={this.props.visVenstreMeny}
                                />
                                {!!(
                                    this.props.minInfo &&
                                    (this.props.minInfo.ErAdmin || this.props.minInfo.ErAvdelingsleder)
                                ) && (
                                    <Search
                                        sokStatusEndret={(status: boolean) => {
                                            this.sokStatusEndret(status);
                                        }}
                                    />
                                )}
                            </div>
                            <div className="ToppMenyRightRight">
                                {!!(
                                    this.props.minInfo &&
                                    (this.props.minInfo.ErAdmin || this.props.minInfo.ErAvdelingsleder)
                                ) && (
                                    <div className="ToppMenyRightRightMarketplace">
                                        <Marketplace />
                                    </div>
                                )}

                                <div className="ToppMenyRightRightMeldinger">
                                    <ToppMenyMeldinger unread={this.state.unread} />
                                </div>

                                <div className="Varslinger">
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <VarslingBell />
                                    </ErrorBoundary>
                                </div>

                                <div className="ToppMenyRightRightHjelp">
                                    <WithPhrases domain="meny">
                                        {(p) => (
                                            <ToppMenyItem
                                                ikon={faQuestionSquare}
                                                tekst={p('top_help') ? p('top_help') : 'Hjelp'}
                                                handling={this.props.toggleHelp}
                                            />
                                        )}
                                    </WithPhrases>
                                </div>

                                <ToppMenyBruker
                                    brukerVises={(status: boolean) => {
                                        this.brukerVises(status);
                                    }}
                                    minInfo={this.props.minInfo}
                                    setUnread={this.setUnread}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private sokStatusEndret(status: boolean) {
        this.setState({ soker: status });
        if (status && this.props.visVenstreMeny) {
            this.props.toggleVenstreMeny();
        }
    }

    private brukerVises(status: boolean) {
        if (status && this.props.visVenstreMeny) {
            this.props.toggleVenstreMeny();
        }
    }

    private hentSokKlasse(): string {
        return this.state.soker ? ' soker' : '';
    }
}

export default ToppMeny;
