
function descriptor(name: string): string {
    const res = 'db_' + name
        .toLowerCase()
        .replace(/æ/g, 'ae')
        .replace(/ø/g, 'oe')
        .replace(/å/g, 'aa')
        .replace(/ /g, '_');

    return res;
}

export default descriptor;

export {
    descriptor,
};
