import { faAddressCard } from '@fortawesome/pro-solid-svg-icons/faAddressCard';
import { faBolt } from '@fortawesome/pro-solid-svg-icons/faBolt';
import { faBook } from '@fortawesome/pro-solid-svg-icons/faBook';
import { faCar } from '@fortawesome/pro-solid-svg-icons/faCar';
import { faChartBar } from '@fortawesome/pro-solid-svg-icons/faChartBar';
import { faCheck } from '@fortawesome/pro-solid-svg-icons/faCheck';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faFileExcel } from '@fortawesome/pro-solid-svg-icons/faFileExcel';
import { faHandshake } from '@fortawesome/pro-solid-svg-icons/faHandshake';
import { faHeart } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faList } from '@fortawesome/pro-solid-svg-icons/faList';
import { faMinus } from '@fortawesome/pro-solid-svg-icons/faMinus';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faSnowflake } from '@fortawesome/pro-solid-svg-icons/faSnowflake';
import { faTasks } from '@fortawesome/pro-solid-svg-icons/faTasks';
import { faUser } from '@fortawesome/pro-solid-svg-icons/faUser';
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers';

export const iconMap = {
    'question-circle': faQuestionCircle,
    heart: faHeart,
    users: faUsers,
    'address-card-o': faAddressCard,
    list: faList,
    check: faCheck,
    car: faCar,
    'bar-chart': faChartBar,
    book: faBook,
    minus: faMinus,
    'snowflake-o': faSnowflake,
    user: faUser,
    'file-excel-o': faFileExcel,
    'clock-o': faClock,
    tasks: faTasks,
    'handshake-o': faHandshake,
    bolt: faBolt,
};

// Preferred way when old asp sites can handle fontawesome svg core
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

// export const iconMap = {
//     'question-circle': icon({ name: 'question-circle' }),
//     heart: icon({ name: 'heart' }),
//     users: icon({ name: 'users' }),
//     'address-card-o': icon({ name: 'address-card' }),
//     list: icon({ name: 'list' }),
//     check: icon({ name: 'check' }),
//     car: icon({ name: 'car' }),
//     'bar-chart': icon({ name: 'bar-chart' }),
//     book: icon({ name: 'book' }),
//     minus: icon({ name: 'minus' }),
//     'snowflake-o': icon({ name: 'snowflake' }),
//     user: icon({ name: 'user' }),
//     'file-excel-o': icon({ name: 'file-excel' }),
//     'clock-o': icon({ name: 'clock' }),
//     tasks: icon({ name: 'tasks' }),
//     'handshake-o': icon({ name: 'handshake' }),
//     bolt: icon({ name: 'bolt' }),
// };
