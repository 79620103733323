import React from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons/faQuestionCircle';
import { faTachometerAltAverage } from '@fortawesome/pro-solid-svg-icons/faTachometerAltAverage';
import { faThumbtack } from '@fortawesome/pro-solid-svg-icons/faThumbtack';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePhrases } from '@tidsbanken/phrases';
import VenstreMenyItem from './VenstreMenyItem';
import './VenstreMeny.less';
import { ApiMe } from '../helpers/types';

type PinProps = {
    active: boolean;
    onClick: () => void;
};

const Pin = (props: PinProps) => {
    const p = usePhrases('meny');

    const className = props.active ? 'Pin active' : 'Pin';

    const title = props.active ? p('left_pin_close') : p('left_pin_open');

    return (
        <a
            className={className}
            title={title}
            href="#"
            onClick={(e) => {
                e.preventDefault();
                props.onClick();
            }}
        >
            <FontAwesomeIcon className="fa thumbtack-pin-icon" icon={faThumbtack} />
        </a>
    );
};

export interface IVenstreMenyItemUnderMenyItem {
    Navn: string;
    Url: string;
}

export interface IVenstreMenyItemProps {
    Id?: number;
    Ikon: string;
    Navn: string;
    Url?: string;
    Underpunkt?: IVenstreMenyItemUnderMenyItem[];
    Action?: () => void;
    StandardIcon?: IconDefinition;
}

interface IVenstreMenyProps {
    toggleVenstreMeny: () => void;
    toggleHelp: () => void;
    vis: boolean;
    minInfo?: ApiMe;
}

interface IVenstreMenyState {
    menyElementer: IVenstreMenyItemProps[];
    pinned: boolean;
}

const ls = window.localStorage;
const html = document.getElementsByTagName('html')[0];

class VenstreMeny extends React.Component<IVenstreMenyProps, IVenstreMenyState> {
    constructor(props: IVenstreMenyProps) {
        super(props);

        this.state = {
            menyElementer: [],
            pinned: !!ls.getItem('menu-pinned'),
        };

        if (this.state.pinned) {
            this.pin();
        }

        this.bindFunctions();

        const apiMeUrl =
            window.location.hostname === 'min.tidsbanken.net'
                ? 'https://api-me.tidsbanken.net/SideMeny'
                : 'https://api-me-sandkasse.tidsbanken.net/SideMeny';

        fetch(apiMeUrl, this.getInit())
            .then((response) => {
                const contentType = response.headers.get('content-type');
                if (contentType && contentType.indexOf('application/json') > -1) {
                    return response.json();
                }
                throw new TypeError('There is no JSON!');
            })
            .then((json) => {
                this.setState({
                    menyElementer: json,
                });
            })
            .catch(() => {
                throw new TypeError('There is no JSON!');
            });
    }

    bindFunctions() {
        this.togglePin = this.togglePin.bind(this);
        this.pin = this.pin.bind(this);
        this.unPin = this.unPin.bind(this);
    }

    togglePin() {
        this.setState((ps) => {
            ps.pinned ? this.unPin() : this.pin();

            return {
                pinned: !ps.pinned,
            };
        });
    }

    pin() {
        html.classList.add('menu-pinned');
        ls.setItem('menu-pinned', 'true');
    }

    unPin() {
        html.classList.remove('menu-pinned');
        ls.removeItem('menu-pinned');
    }

    render() {
        const hasSubMenus = this.state.menyElementer.reduce((a, b) => (!a ? !!b.Underpunkt : true), false);

        return (
            <div className={'VenstreMenyContainer ' + (this.props.vis ? '' : 'hide-mobile')}>
                <div
                    className="VenstreMeny"
                    style={{
                        overflowY: hasSubMenus ? undefined : 'auto',
                    }}
                >
                    <Pin active={this.state.pinned} onClick={this.togglePin} />
                    {!!(this.props.minInfo && (this.props.minInfo.ErAdmin || this.props.minInfo.ErAvdelingsleder)) && (
                        <VenstreMenyItem
                            key={0}
                            Ikon={'fa fa-dashboard'}
                            Navn={'Dashboard'}
                            Url={'dash.asp'}
                            StandardIcon={faTachometerAltAverage}
                        />
                    )}

                    <VenstreMenyItem key={1} Ikon={'fa fa-play'} Navn={'Hjem'} Url={'/hjem'} StandardIcon={faPlay} />

                    <br />

                    {this.state.menyElementer.map((elem) => {
                        return (
                            <VenstreMenyItem
                                key={elem.Id === -1 ? elem.Navn : elem.Id}
                                Ikon={elem.Ikon}
                                Navn={elem.Navn}
                                Url={elem.Url}
                                Underpunkt={elem.Underpunkt}
                            />
                        );
                    })}

                    <div className="HjelpItem">
                        <br />

                        <VenstreMenyItem
                            key={2}
                            Ikon={'fa-question'}
                            Navn={'Hjelp'}
                            Action={this.props.toggleHelp}
                            StandardIcon={faQuestionCircle}
                        />
                    </div>
                </div>

                <div
                    className="VenstreMenyOverlay"
                    onTouchStart={() => {
                        this.props.toggleVenstreMeny();
                    }}
                    onClick={() => {
                        this.props.toggleVenstreMeny();
                    }}
                />
            </div>
        );
    }

    private getInit() {
        const myHeaders = new Headers();
        const cred: RequestCredentials = 'include';
        const requestmode: RequestMode = 'cors';
        const myInit: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            mode: requestmode,
            credentials: cred,
        };
        return myInit;
    }
}

export default VenstreMeny;
