import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material';
import { init } from '@tidsbanken/phrases';
import Meny from './components/Meny';
import { muiMenuTheme } from './muiTheme';
import './index.less';

init('meny');

const container = document.getElementById('navmeny') as HTMLElement;
const root = createRoot(container);

root.render(
    <ThemeProvider theme={muiMenuTheme}>
        <Meny />
    </ThemeProvider>
);
