import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { appendScript } from '../helpers/appendScript';
import betalingsMelding from '../helpers/betalingsMelding';
import { setUserKeyAuthValues } from '../helpers/setUserKeyAuthValues';
import { ApiMe } from '../helpers/types';
import KunngjoringsMelding from './KunngjoringsMelding/KunngjoringsMelding';
import DbAlert from './DbAlert';
import Help from './Help';
import IdleTimer from './IdleTimer';
import ToppMeny from './ToppMeny';
import touch from './touch';
import VenstreMeny from './VenstreMeny';
import './ToppMeny.less';

interface IMenyState {
    visVenstreMeny: boolean;
    minInfo?: ApiMe;
    feiletOgLasteMinInfo: boolean;
    visHjelp: boolean;
}

const identifyTouchDevices = () => {
    const html = document.getElementsByTagName('html')[0];
    html.classList.add(touch() ? 'touch' : 'no-touch');
};

class Meny extends React.Component<unknown, IMenyState> {
    static lastY?: number;
    static time: number;

    constructor(props: unknown, state: IMenyState) {
        super(props, state);
        this.state = {
            visVenstreMeny: false,
            visHjelp: false,
            feiletOgLasteMinInfo: false,
        };

        setUserKeyAuthValues();
        identifyTouchDevices();
        appendScript('https://q41f06qpd7g8.statuspage.io/embed/script.js');
        this.fetchMyInformation();
        this.toggleHelp = this.toggleHelp.bind(this);
        this.hideOrShowApp = this.hideOrShowApp.bind(this);
    }

    toggleOverflowScroll() {
        !this.state.visVenstreMeny ? this.disableOverflowScroll() : this.enableOverflowScroll();
    }

    disableOverflowScroll() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        document.ontouchstart = function (e: TouchEvent) {
            Meny.lastY = e.touches[0].clientY;
            Meny.time = Date.now();
        };
        document.onwheel = function (e: Event) {
            that.preventDefault(e);
        };
        document.ontouchmove = function (e: Event) {
            that.preventDefault(e);
        };
        document.ontouchend = () => {
            Meny.lastY = undefined;
        };
    }

    preventDefault(e: Event) {
        const vm = document.getElementsByClassName('VenstreMeny')[0] as HTMLElement;
        const dir = this.direction(e);

        if (
            dir === 0 ||
            vm.scrollHeight === vm.clientHeight ||
            (vm.scrollHeight - vm.clientHeight <= vm.scrollTop && dir < 0) ||
            (vm.scrollTop <= 0 && dir > 0)
        ) {
            e.preventDefault();
            e.returnValue = false;
            e.stopPropagation();
            e.cancelBubble = true;
        }
    }

    direction(e: Event): number {
        const yObj = e as {
            deltaY?: number;
            changedTouches?: TouchList;
            touches?: TouchList;
        };
        if (yObj.deltaY) {
            // scroll
            if (yObj.deltaY < 0) {
                return 1;
            } else {
                return -1;
            }
        }

        if (yObj.changedTouches) {
            // touch
            const siste = Meny.lastY;
            if (siste) {
                if (yObj.changedTouches[0].clientY > siste + 5 && Date.now() - Meny.time > 3) {
                    return 1;
                }
                if (yObj.changedTouches[0].clientY < siste - 5 && Date.now() - Meny.time > 3) {
                    return -1;
                }
            }
        }
        return 0;
    }

    enableOverflowScroll() {
        document.onwheel = this.allowDefault;
        document.ontouchmove = this.allowDefault;
        document.ontouchend = this.allowDefault;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    allowDefault(e: Event) {
        return;
    }

    isMobile() {
        const userAgent = navigator.userAgent;

        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }

    fetchMyInformation() {
        const apiMeUrl =
            window.location.hostname === 'min.tidsbanken.net'
                ? 'https://api-me.tidsbanken.net/'
                : 'https://api-me-sandkasse.tidsbanken.net';

        fetch(apiMeUrl, this.getInit())
            .then((res) => res.json())
            .then((data: ApiMe) => {
                this.setState({
                    minInfo: data as ApiMe,
                });
                betalingsMelding(data);

                if (data.LoggetInnMedUserKey && data.ErAdmin && !window.disableLiveChat) {
                    if (!this.isMobile()) {
                        appendScript('https://js-na1.hs-scripts.com/6056195.js', 'hs-script-loader');

                        const addClassToIframe = () => {
                            const iframeContainer = document.getElementById('hubspot-messages-iframe-container');
                            if (iframeContainer) {
                                iframeContainer.className += ' tb-hubspot-container';
                            } else {
                                setTimeout(addClassToIframe, 500);
                            }
                        };
                        addClassToIframe();
                    }
                }
            })
            .catch(() => {
                // tslint:disable-next-line:no-console
                console.error('Feilet på å laste brukers data');
                this.setState({
                    feiletOgLasteMinInfo: true,
                });
            });
    }

    toggleHelp() {
        this.setState((prevState) => {
            this.hideOrShowApp(prevState.visHjelp);

            return {
                visVenstreMeny: false,
                visHjelp: !prevState.visHjelp,
            };
        });
    }

    hideOrShowApp(show: boolean) {
        const app = document.getElementById('hovedinnhold');

        if (app) {
            if (!show) {
                app.style.display = 'none';
            } else {
                app.removeAttribute('style');
            }
        }
    }

    render() {
        return (
            <>
                <div className="Meny">
                    <ToppMeny
                        toggleVenstreMeny={() => {
                            this.toggleOverflowScroll();
                            this.setState((prevState) => ({
                                visVenstreMeny: !prevState.visVenstreMeny,
                            }));
                        }}
                        visVenstreMeny={this.state.visVenstreMeny}
                        toggleHelp={this.toggleHelp}
                        minInfo={this.state.minInfo}
                    />
                    <ErrorBoundary
                        FallbackComponent={() => {
                            return <></>;
                        }}
                    >
                        <DbAlert minInfo={this.state.minInfo} />
                    </ErrorBoundary>
                    {this.state.minInfo && (this.state.minInfo.LoggetInnMedUserKey ? null : <IdleTimer />)}
                    <VenstreMeny
                        toggleVenstreMeny={() => {
                            this.toggleOverflowScroll();
                            this.setState((prevState) => ({
                                visVenstreMeny: !prevState.visVenstreMeny,
                            }));
                        }}
                        toggleHelp={this.toggleHelp}
                        vis={this.state.visVenstreMeny}
                        minInfo={this.state.minInfo}
                    />

                    <Help visHjelp={this.state.visHjelp} minInfo={this.state.minInfo} />
                </div>
                <KunngjoringsMelding minInfo={this.state.minInfo} />
            </>
        );
    }

    private getInit() {
        const myHeaders = new Headers();
        const cred: RequestCredentials = 'include';
        const requestmode: RequestMode = 'cors';
        const myInit: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            mode: requestmode,
            credentials: cred,
        };
        return myInit;
    }
}

export default Meny;
