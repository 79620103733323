import { useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { faBell } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Box, IconButton } from '@mui/material';
import { mutate } from 'swr';
import { apiVarslingerCountUrl, singleItemDelete } from '../../helpers/apiHelpers';
import useCount from '../../hooks/use-Count';
import useCloseVarslingMenuOnClickOutside from '../../hooks/use-varslingClickOutside';
import { ErrorFallback } from '../UI/ErrorFallbackBell';
import VarslingMenu from './VarslingMenu';
import './VarslingMenu.less';

export const VarslingBell = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { count } = useCount();

    const toggleMenu = async () => {
        setIsMenuOpen(!isMenuOpen);
        if (count > 0) {
            await singleItemDelete(apiVarslingerCountUrl);
            await mutate(
                apiVarslingerCountUrl,
                () => {
                    return 0;
                },
                { revalidate: false, optimisticData: 0 }
            );
        }
    };
    const varslingMenuRef = useRef(null);
    useCloseVarslingMenuOnClickOutside(varslingMenuRef, toggleMenu);
    const isDisabled = window.location.pathname === '/varslinger';

    // Get the current month (0-indexed, so December is 11)
    const currentMonth = new Date().getMonth();

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Box>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    badgeContent={count}
                    color="error"
                    sx={{ fontSize: '1.25rem' }}
                >
                    <IconButton
                        color="primary"
                        id="basic-button"
                        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={isMenuOpen ? 'true' : undefined}
                        onClick={toggleMenu}
                        disabled={isDisabled}
                        sx={{ width: '2.75rem', height: '2.75rem' }}
                    >
                        {/** TODO: Eivind skal lage julestemning for alle i hele verden (pluss fikse at du kan klikke på bjella at den lukker seg og ikke åpner seg igjen) */}
                        {currentMonth === 11 ? (
                            <img
                                style={{ width: '2.75rem' }}
                                src={require('../../static/images/bell_christmas.webp')}
                                alt={'christmas bell'}
                            />
                        ) : (
                            <FontAwesomeIcon icon={faBell} style={{ color: isDisabled ? '#728ba0' : '#29343D' }} />
                        )}
                    </IconButton>
                </Badge>

                <VarslingMenu isMenuOpen={isMenuOpen} toggleMenu={toggleMenu} />
            </Box>
        </ErrorBoundary>
    );
};

export default VarslingBell;
