import * as React from 'react';
import { Avatar } from '@mui/material';
import { WithPhrases } from '@tidsbanken/phrases';
import { stringAvatar } from '../helpers/muiHelpers';
import { ApiMe } from '../helpers/types';
import './ToppMenyBruker.less';

interface IToppMenyBrukerProps {
    brukerVises: (status: boolean) => void;
    minInfo?: ApiMe;
    setUnread: (num: number) => void;
}

interface IToppMenyBrukerState {
    visPulldown: boolean;
    aktivitetId: string;
    aktivitet: string;
    prosjekt: string;
    prosjektLinje: string;
    bilde: string;
    innstemplet: boolean;
    stempletSide: Date;
}

class ToppMenyBruker extends React.Component<IToppMenyBrukerProps, IToppMenyBrukerState> {
    private wrapper: HTMLElement;
    private listeningOnClick: boolean = false;

    constructor(props: IToppMenyBrukerProps) {
        super(props);

        this.state = {
            visPulldown: false,
            aktivitet: '',
            prosjekt: '',
            prosjektLinje: '',
            bilde: '',
            aktivitetId: '',
            innstemplet: false,
            stempletSide: new Date(),
        };

        const apiMeUrl =
            window.location.hostname === 'min.tidsbanken.net'
                ? 'https://api-me.tidsbanken.net/Status'
                : 'https://api-me-sandkasse.tidsbanken.net/Status';

        fetch(apiMeUrl, this.getInit())
            .then((res) => res.json())
            .then((responseData: IBrukerStatus) => {
                this.props.setUnread(responseData.UlesteMeldinger);
                if (responseData.Innstemplet) {
                    this.setState({
                        innstemplet: true,
                        stempletSide: new Date(responseData.InnstempletData.InnstempletSiden),
                        aktivitetId: responseData.InnstempletData.AktivitetId,
                        aktivitet: responseData.InnstempletData.AktivitetNavn,
                        prosjekt: responseData.InnstempletData.ProsjektNavn,
                        prosjektLinje: responseData.InnstempletData.ProsjektLinjeNavn,
                        bilde: responseData.BildeUrl,
                    });
                } else {
                    this.setState({
                        innstemplet: false,
                        bilde: responseData.BildeUrl,
                    });
                }
            });
    }

    getInitials(): string {
        const firstName = (this.props.minInfo && this.props.minInfo.Fornavn.toUpperCase()) || '';
        const lastName = (this.props.minInfo && this.props.minInfo.Etternavn.toUpperCase()) || '';

        return firstName.substring(0, 1) + lastName.substring(0, 1);
    }

    getName(): string | undefined {
        const firstName = (this.props.minInfo && this.props.minInfo.Fornavn) || undefined;
        const lastName = (this.props.minInfo && this.props.minInfo.Etternavn) || undefined;

        return firstName ? `${firstName} ${lastName}`.trim() : undefined;
    }

    stringToColour(str: string) {
        str = (str ?? '').trim().toUpperCase().replace(/\s/g, '');
        if (!str) return '#f0f2f2';
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        const saturation = 0.65; // Saturation factor (0-1)
        const lightness = 0.8; // Lightness factor (0-1)
        for (let i = 0; i < 3; i++) {
            const baseValue = (hash >> (i * 8)) & 0xff;
            const newValue = Math.floor(baseValue * saturation + lightness * 255 * (1 - saturation));
            colour += newValue.toString(16).padStart(2, '0').slice(-2);
        }
        return colour;
    }

    handleOnClickEmployee = () => {
        if (!this.props.minInfo) return;
        const employeeId = this.props.minInfo.Id;
        if (!window.employeePanel) {
            this.visSkjulPulldown();
        } else {
            if (window.employeePanel.isOpen && window.employeePanel.employeeId === employeeId) {
                window.employeePanel.close();
            } else {
                window.employeePanel.open(employeeId, {
                    openPanels: [''],
                });
            }
        }
    };

    render() {
        return (
            <WithPhrases domain="meny">
                {(p) => (
                    <div
                        className="ToppMenyBruker"
                        ref={(wrapper) => {
                            if (wrapper !== null) {
                                this.wrapper = wrapper;
                            }
                        }}
                    >
                        <a
                            onClick={() => {
                                this.handleOnClickEmployee();
                            }}
                        >
                            {this.getName() ? (
                                <Avatar
                                    alt={this.getName()}
                                    src={this.state.bilde}
                                    {...stringAvatar(this.getName() ?? '')}
                                    className="avatar"
                                >
                                    {this.getInitials()}
                                </Avatar>
                            ) : (
                                <Avatar className="avatar" />
                            )}
                        </a>

                        <div className={'PulldownWrapper ' + (this.state.visPulldown ? 'synlig' : 'skjult')}>
                            <div className="PulldownInnhold">
                                <div className="InnholdVenstre">
                                    <div className="BrukerBilde">
                                        {this.state.bilde ? (
                                            <img alt="user-image" src={this.state.bilde} />
                                        ) : (
                                            <div style={{ backgroundColor: this.stringToColour(this.getName() ?? '') }}>
                                                <p>{this.getInitials()}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="InnholdHoyre">
                                    <p className="navn">
                                        <strong>{this.getName()}</strong>
                                    </p>
                                    <p>
                                        {this.state.innstemplet && (
                                            <span>
                                                {this.state.aktivitet && (
                                                    <a
                                                        className="brukerAkt"
                                                        href={'/akt.asp?id=' + this.state.aktivitetId}
                                                    >
                                                        <span className="aktType">{p('pill_activity')}</span>
                                                        <span className="aktNavn">{this.state.aktivitet}</span>
                                                    </a>
                                                )}
                                                {this.state.aktivitet && <br />}
                                                <span>{p('top_drop_stamp')}</span>{' '}
                                                <time dateTime={this.state.stempletSide.toISOString()}>
                                                    {this.state.stempletSide.toLocaleTimeString()}
                                                </time>
                                            </span>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className="PulldownKnapper">
                                <a href="/ansatte_profil.asp">{p('top_drop_profile')}</a>
                                <a
                                    onClick={() => {
                                        const authUrl =
                                            window.location.hostname === 'min.tidsbanken.net'
                                                ? 'https://auth.tidsbanken.net'
                                                : 'https://auth-sandkasse.tidsbanken.net';

                                        fetch(`${authUrl}/api/SignOut/Bruker`, {
                                            mode: 'no-cors',
                                            credentials: 'include',
                                        }).then(() => {
                                            window.location.href = authUrl;
                                        });
                                    }}
                                >
                                    {p('top_drop_logout') ? p('top_drop_logout') : 'Logg ut'}
                                </a>
                            </div>
                        </div>
                    </div>
                )}
            </WithPhrases>
        );
    }

    private getInit() {
        const myHeaders = new Headers();
        const cred: RequestCredentials = 'include';
        const requestmode: RequestMode = 'cors';
        const myInit: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            mode: requestmode,
            credentials: cred,
        };
        return myInit;
    }

    private visSkjulPulldown() {
        this.props.brukerVises(!this.state.visPulldown);

        if (!this.listeningOnClick) {
            const eventHandler = (e: Event) => {
                if (!this.wrapper.contains(e.target as Node) && this.state.visPulldown) {
                    this.visSkjulPulldown();
                }
            };

            ['click', 'touchstart'].map((eventName) => {
                document.addEventListener(eventName, eventHandler);
            });

            this.listeningOnClick = true;
        }

        this.setState({
            visPulldown: !this.state.visPulldown,
        });
    }
}

interface IBrukerStatus {
    Innstemplet: boolean;
    InnstempletData: {
        AktivitetId: string;
        AktivitetNavn: string;
        AnleggId: number;
        AnleggNavn: string;
        ArbeidsTypeId: string;
        ArbeidsTypeNavn: string;
        AvdelingId: string;
        AvdelingNavn: string;
        InnstempletSiden: string;
        ProsjektId: string;
        ProsjektLinjeId: string;
        ProsjektLinjeNavn: string;
        ProsjektNavn: string;
    };
    SjaTilBehandling: boolean;
    UlesteMeldinger: number;
    BildeUrl: string;
}

export default ToppMenyBruker;
