import { useEffect, useRef, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePhrases } from '@tidsbanken/phrases';
import descriptor from '../helpers/descriptor';
import { iconMap } from '../helpers/iconHelper';
import slash from '../helpers/slash';
import { IVenstreMenyItemUnderMenyItem } from './VenstreMeny';
import './VenstreMenyItem.less';

type Props = {
    Id?: number;
    Ikon: string;
    Navn: string;
    Url?: string;
    Underpunkt?: IVenstreMenyItemUnderMenyItem[];
    Action?: () => void;
    StandardIcon?: IconDefinition;
};

const VenstreMenyItem = ({ Ikon, Navn, Action, StandardIcon, Underpunkt, Url }: Props) => {
    const [underMenyUtvidet, setUnderMenyUtvidet] = useState(false);
    const [currentOffset, setCurrentOffset] = useState(0);
    const subMenuRef = useRef<HTMLDivElement>(null);
    const p = usePhrases('meny');

    useEffect(() => {
        if (subMenuRef) {
            repositionSubMenu();
            window.addEventListener('resize', () => {
                repositionSubMenu();
            });
        }
    }, [subMenuRef]);

    const repositionSubMenu = () => {
        if (subMenuRef && subMenuRef.current) {
            const rect = subMenuRef.current.getBoundingClientRect();
            let newOffset = window.innerHeight - rect.bottom + currentOffset;

            newOffset = Math.min(0, newOffset);

            subMenuRef.current.style.transform = `translateY(${newOffset}px)`;
            setCurrentOffset(newOffset);
        }
    };

    const current = window.location.pathname.slice(1);

    let isCurrent = false;
    if (Underpunkt) {
        Underpunkt.map((e) => {
            if (e.Url.split(' ')[0] === current) {
                isCurrent = true;
            }
        });
    } else if (Url && Url.split(' ')[0] === current) {
        isCurrent = true;
    }

    const iconName = Ikon.replace(/^fa[s]?[- ]/, '');
    let faIcon = StandardIcon ? StandardIcon : iconMap[iconName as keyof typeof iconMap];

    if (!faIcon) {
        console.warn(`Icon ${iconName} not found`);
        faIcon = iconMap['minus'];
    }

    return (
        <div
            className={`VenstreMenyItem ${underMenyUtvidet ? 'undermeny-utvidet ' : ''}${isCurrent ? 'current' : ''} ${Navn === 'Hjem'} ? 'separator' : '' `}
        >
            <a
                href={Url ? slash(Url) : ''}
                onClick={(e) => {
                    if (Underpunkt) {
                        e.preventDefault();
                        // Undermeny eksisterer, vis/gjem den
                        setUnderMenyUtvidet(!underMenyUtvidet);
                    } else {
                        // Undermeny finnes ikke, gå til url
                        if (Action) {
                            Action();
                        }
                    }
                }}
            >
                <div className="VenstreMenyItemMain">
                    <div className={'menyitem-left'}>
                        <div>
                            <FontAwesomeIcon className="menyitem-ikon fa" icon={faIcon} />
                        </div>
                        <p className={'menyitem-navn'}>{p(descriptor(Navn)) || Navn}</p>
                    </div>
                    <span className="menyitem-caret">
                        {Underpunkt && <FontAwesomeIcon className={'fa fa-caret-down'} icon={faCaretDown} />}
                    </span>
                </div>
            </a>
            {Underpunkt && (
                <AnimateHeight
                    duration={300}
                    height={underMenyUtvidet ? 'auto' : 0}
                    className={'VenstreMenyItemUnderMeny'}
                    easing={'ease'}
                >
                    <div className="PaddingWrapper">
                        {Underpunkt.map((elem, i) => {
                            return (
                                <a key={i} href={slash(elem.Url)}>
                                    <div
                                        className={
                                            'VenstreMenyItemUnderMenyItem ' +
                                            (elem.Url.split(' ')[0] === current ? 'current' : '')
                                        }
                                    >
                                        <p>{p(descriptor(elem.Navn)) || elem.Navn}</p>
                                    </div>
                                </a>
                            );
                        })}
                    </div>
                </AnimateHeight>
            )}
            {Underpunkt && (
                <div className={'VenstreMenyItemUnderMenyDesktop'} ref={subMenuRef}>
                    {Underpunkt.map((elem, i) => {
                        return (
                            <a key={i} href={slash(elem.Url)}>
                                <div
                                    className={
                                        'VenstreMenyItemUnderMenyItem ' +
                                        (elem.Url.split(' ')[0] === current ? 'current' : '')
                                    }
                                >
                                    <p>{p(descriptor(elem.Navn)) || elem.Navn}</p>
                                </div>
                            </a>
                        );
                    })}
                </div>
            )}
        </div>
    );
};
export default VenstreMenyItem;
