import React from 'react';
import { faExternalLink } from '@fortawesome/pro-solid-svg-icons/faExternalLink';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SupportDokument } from './index';
import './HelpDocument.less';

type HelpDocumentProps = {
    document: SupportDokument;
    minInfo?: ApiMe;
};

export default class HelpDocument extends React.Component<HelpDocumentProps, unknown> {
    constructor(props: HelpDocumentProps) {
        super(props);
    }

    render() {
        return (
            <div className="HelpDocument">
                <div>
                    <h4>KundeID: {this.props.minInfo ? this.props.minInfo.FirmaId : 'Fant ikke firma-ID'}</h4>
                    <a
                        className="HelpExternalLink"
                        href={'https://tidsbanken.net/support/case.asp?ID=' + this.props.document.ID}
                        target="_blank"
                        rel="noreferrer"
                    >
                        Åpne hjelpeartikkelen i ny fane
                        <FontAwesomeIcon icon={faExternalLink} />
                    </a>
                    <hr />
                </div>

                <h1>{this.props.document.DocHeadline}</h1>
                <h3>{this.props.document.DocSubheadline}</h3>

                <div className="HelpContent">
                    <div
                        className="HelpMainContent"
                        dangerouslySetInnerHTML={{
                            __html: this.props.document.DocText,
                        }}
                    />
                    <div
                        className="HelpSideContent"
                        dangerouslySetInnerHTML={{
                            __html: this.props.document.VenstreBeskrivelse,
                        }}
                    />
                </div>
            </div>
        );
    }
}
