import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { useLocalStorage } from '../hooks/use-localStorage';
import TbButton from './UI/TbButton';
import './IdleTimer.less';

const IdleTimer = () => {
    const defaultValue = 45;
    const [timeFromLocalStorage] = useLocalStorage('tb_timeout_before_inactivity', defaultValue);
    const validTimeout =
        isNaN(timeFromLocalStorage) || timeFromLocalStorage > 600 || timeFromLocalStorage < 30
            ? defaultValue
            : timeFromLocalStorage;

    const timeout = validTimeout * 1000;
    const promptTimeout = 1000 * 15;
    const [open, setOpen] = useState<boolean>(false);
    const [remaining, setRemaining] = useState<number>(0);
    const [isFirstTimeIdle, setIsFirstTimeIdle] = useState(true);
    const p = usePhrases('meny');

    const handleSignOut = () => {
        const authUrl =
            window.location.hostname === 'min.tidsbanken.net'
                ? 'https://auth.tidsbanken.net'
                : 'https://auth-sandkasse.tidsbanken.net';
        fetch(`${authUrl}/api/SignOut/Bruker`, {
            mode: 'no-cors',
            credentials: 'include',
        }).then(() => {
            window.location.href = authUrl;
        });
    };

    const onPrompt = () => {
        setOpen(true);
        setRemaining(Math.ceil(promptTimeout / 1000));
    };

    const onIdle = () => {
        setOpen(false);
        setRemaining(0);
        handleSignOut();
    };

    const onActive = () => {
        setOpen(false);
        setRemaining(0);
    };

    const { getRemainingTime, isPrompted, activate } = useIdleTimer({
        timeout,
        promptTimeout,
        onPrompt,
        onIdle,
        onActive,
        crossTab: true,
    });

    const handleStillHere = () => {
        setOpen(false);
        activate();
        setIsFirstTimeIdle(false);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            if (isPrompted()) {
                setRemaining(Math.ceil(getRemainingTime() / 1000));
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [getRemainingTime, isPrompted]);

    return (
        <Dialog
            fullWidth
            maxWidth="mobile"
            open={open}
            onClose={handleStillHere}
            TransitionProps={{ unmountOnExit: true }}
            transitionDuration={{
                appear: 225,
                exit: 10,
            }}
        >
            <DialogTitle>{p('idle_timer_header')}</DialogTitle>
            <DialogContent>
                <Typography>
                    {p('idle_timer_modal_content')} {p('idle_timer_logged_out_text')}{' '}
                    <strong>
                        {remaining} {p('idle_timer_modal_seconds')}.
                    </strong>{' '}
                </Typography>
                {!isFirstTimeIdle && (
                    <Typography className="TimerText" fontWeight={'bold'}>
                        <br />
                        {p('idle_timer_modal_new_text')}
                    </Typography>
                )}
            </DialogContent>
            <DialogActions>
                <TbButton onClick={handleSignOut} color="primary">
                    {p('idle_timer_modal_close_btn')}
                </TbButton>
                <TbButton onClick={handleStillHere} color="primary" variant="contained">
                    {p('idle_timer_modal_action_btn')}
                </TbButton>
            </DialogActions>
        </Dialog>
    );
};

export default IdleTimer;
