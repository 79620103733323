import React from "react"
import {useEffect} from "react";

const useCloseVarslingMenuOnClickOutside = (ref: React.RefObject<HTMLElement>, onClickOutside: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>): void => {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                if (event.target instanceof SVGElement) {
                    return;
                }

                if (event.target instanceof HTMLElement) {
                    if (event.target.className.includes('ui')) {
                        return;
                    }
                    if (event.target.className.includes('ul')) {
                        return;
                    }
                    if (event.target.className.includes('button')) {
                        return;
                    }
                    if (event.target.className.includes('notification-options-menu-item')) {
                        return;
                    }
                    if (event.target.className.includes('ToppMenyItem')) {
                        return;
                    }
                }

                onClickOutside();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onClickOutside]);
};


export default useCloseVarslingMenuOnClickOutside;
