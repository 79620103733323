import './TBLogo.less';

const TBLogo = (props: { className?: string }) => {
    // tslint:disable:max-line-length
    return (
        <svg
            className={'TBLogo ' + (props.className ? props.className : '')}
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 418.33 87.83"
        >
            <title>Tidsbanken_logo_rgb</title>
            <polygon className="cls-1" points="43.92 71.25 49.28 87.83 38.55 87.83 43.92 71.25" />
            <polygon className="cls-1" points="43.91 16.58 38.55 0 49.28 0 43.91 16.58" />
            <polygon className="cls-1" points="30.25 67.59 26.6 84.63 17.31 79.27 30.25 67.59" />
            <polygon className="cls-1" points="57.58 20.24 61.23 3.2 70.52 8.56 57.58 20.24" />
            <polygon className="cls-1" points="16.58 43.92 0 49.28 0 38.55 16.58 43.92" />
            <polygon className="cls-1" points="71.25 43.91 87.83 38.55 87.83 49.28 71.25 43.91" />
            <polygon className="cls-1" points="20.24 30.25 3.2 26.6 8.56 17.31 20.24 30.25" />
            <polygon className="cls-1" points="67.59 57.58 84.63 61.23 79.27 70.52 67.59 57.58" />
            <polygon className="cls-1" points="30.25 20.25 17.31 8.56 26.6 3.2 30.25 20.25" />
            <path
                className="cls-1"
                d="M456.08,101.71V78.42c0-5.28-3.21-10-13-10-5.52,0-9.7.91-12.79,2.37v30.93h7.4V74a12.38,12.38,0,0,1,5.09-.91c4.67,0,5.88,2.79,5.88,5.46v23.17ZM416.66,82.24,403.14,84.3C403,76.48,405.93,73,410.23,73c4.07,0,6.43,2.67,6.43,9.28m6.92,2.91c.3-11-4.43-16.74-13.1-16.74s-14,6.19-14,16.86c0,11,5.88,17,15.16,17,5,0,8.49-1,10.79-2.31l-1.75-4.67a17.85,17.85,0,0,1-8.25,1.82c-5.4,0-8.31-3-8.92-9.1Zm-29.3,16.56L381.67,84,393,69h-7.52l-10.37,14.8h-.12V55.1l-7.34,3v43.61h7.34V85h.12L386,101.71Zm-35.81,0V78.42c0-5.28-3.21-10-13-10-5.52,0-9.7.91-12.79,2.37v30.93h7.39V74a12.47,12.47,0,0,1,5.1-.91c4.67,0,5.88,2.79,5.88,5.46v23.17ZM316.9,97.28a10.36,10.36,0,0,1-4.43.91c-4.31,0-6.85-1.82-6.85-6.06,0-5.95,4.3-7.46,11.28-8.31Zm6.91,2.67V79.69c0-8.43-5.34-11.28-12.07-11.28a26,26,0,0,0-11.22,2.49l1.7,4.25a19.41,19.41,0,0,1,8.19-2c3.82,0,6.49,1.16,6.49,5.4V80.3c-9.95,1-18.26,3.46-18.26,12.07,0,6.31,4.43,9.95,13.34,9.95,5.1,0,9.22-1,11.83-2.37M285.21,85c0,7.76-2.37,12.74-8.19,12.74a10,10,0,0,1-3.76-.67V73.87a8.39,8.39,0,0,1,4.43-1c5.58,0,7.52,5.34,7.52,12.13m7.34-.42c0-9.77-5.28-16-12.86-16a13.94,13.94,0,0,0-6.43,1.34V55.1l-7.34,3V100c2.06,1.33,5.76,2.37,10.8,2.37,10.19,0,15.83-6.74,15.83-17.71"
                transform="translate(-37.75 -33.17)"
            />
            <path
                className="cls-2"
                d="M258.43,92.31c0-3.82-1.45-7.1-7.82-9.64-2.85-1.16-4.85-2.49-4.85-5.16,0-2.3,1.64-4.31,4.67-4.31A8.71,8.71,0,0,1,255.64,75l1.82-4.3A15,15,0,0,0,249,68.41c-6.24,0-10.37,4.25-10.37,9.46,0,3.4,1.28,6.8,7.46,9.16,3.34,1.28,4.79,2.61,4.79,5.4s-2,4.61-5,4.61a10.23,10.23,0,0,1-6.13-1.88l-1.94,4.73a16.62,16.62,0,0,0,9,2.36c7.16,0,11.64-4.6,11.64-9.94m-35,4.55a7.17,7.17,0,0,1-3.82.72c-5.7,0-8.07-4.85-8.07-12.25,0-6.91,2.19-12.43,8.62-12.43a11.28,11.28,0,0,1,3.27.43Zm7.4,3.09V55.1l-7.4,3V69.32a17.76,17.76,0,0,0-4.91-.6c-8.49,0-14.32,6.36-14.32,17,0,10.37,5.58,16.56,15.23,16.56,5.09,0,8.79-.91,11.4-2.37M196.33,69h-7.4v32.69h7.4Zm-12.91-4.17V58.27H152.77v6.58H164.4v36.86h7.7V64.85Z"
                transform="translate(-37.75 -33.17)"
            />
            <rect className="cls-2" x="151.18" y="25.1" width="7.34" height="6.57" />
            <polygon
                className="cls-2"
                points="83.83 25.15 80.14 18.74 4.05 62.53 6.05 66.01 40.89 45.96 62.69 83.7 69.09 80 49 45.2 83.83 25.15"
            />
        </svg>
    );
};

export default TBLogo;
