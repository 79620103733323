// --- used by memoize -------------------------------------------------------------------------------------------------

export type F<P extends any[], R> = (...p: P) => R;
export type C<T> = { [k: string]: T | undefined };
export const memoize =
    <P extends any[], R>(f: F<P, R>, c: C<R> = {}) =>
    (...p: P) =>
        ((k: string) => c[k] ?? (c[k] = f(...p)))(JSON.stringify(p));

// --- URLs ------------------------------------------------------------------------------------------------------------

const minDomainURL = 'min.tidsbanken.net';

export const getNewEnvironment = (domain: string) =>
    window.location.hostname === minDomainURL
        ? `https://api-${domain}.tidsbanken.net`
        : `https://api-${domain}-sandkasse.tidsbanken.net`;

// --- Api-Varslinger --------------------------------------------------------------------------------------------------

export const apiVarslingerUrl = getNewEnvironment('varslinger');
export const apiVarslingVarslingerUrl = apiVarslingerUrl + '/varslinger';
export const apiVarslingSettingsUrl = apiVarslingerUrl + '/settings';
export const apiVarslingSettingsUserUrl = apiVarslingerUrl + '/settings/user';
export const apiVarslingSettingsCustomerUrl = apiVarslingerUrl + '/settings/customer';
export const apiVarslingerVarslingerUrl = apiVarslingVarslingerUrl + '/varslinger';
export const apiVarslingerCountUrl = apiVarslingVarslingerUrl + '/count';
export const apiVarslingerSettingsUserModulesUrl = apiVarslingSettingsUserUrl + '/modules';
export const apiVarslingerSettingsCustomerModulesUrl = apiVarslingSettingsCustomerUrl + '/modules';
export const apiVarslingerReadAllUrl = apiVarslingVarslingerUrl + '/readAll';
export const apiVarslingerReadUrl = apiVarslingVarslingerUrl + '/read';
export const apiVarslingerRemoveAllUrl = apiVarslingVarslingerUrl + '/removeAll';
export const apiVarslingerRemoveUrl = apiVarslingVarslingerUrl + '/remove';

export const patchReadAll = () => singleItemPatch(apiVarslingerReadAllUrl);
export const patchRead = (ids: string[], keepalive = false) => singleItemPatch(apiVarslingerReadUrl, keepalive, ids);
export const deleteRemoveAll = () => singleItemDelete(apiVarslingerRemoveAllUrl);
export const deleteRemove = (ids: string[]) => singleItemDelete(apiVarslingerRemoveUrl, ids);
export const deleteRemoveCount = () => singleItemDelete(apiVarslingerCountUrl, []);

// --- fetch -----------------------------------------------------------------------------------------------------------

export const singleItemFetch = async (url: string) => {
    const response = await fetch(`${url}`, {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    if (response.status > 200 && response.status < 400) {
        return null;
    }
    return response.json();
};

export const singleItemDelete = (url: string, body?: any) => {
    return fetch(`${url}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    });
};

export const singleItemPatch = (url: string, keepAlive = false, body?: any) => {
    return fetch(`${url}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
        keepalive: keepAlive,
    });
};

export const singleItemPost = (url: string) => {
    return fetch(`${url}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

// --- Utility ---------------------------------------------------------------------------------------------------------

const applyQueryParams = (url: string, params: { [key: string]: string | undefined }): string => {
    const queryParams = Object.entries(params)
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${key}=${encodeURIComponent(value as string)}`)
        .join('&');

    return queryParams ? `${url}?${queryParams}` : url;
};
