
function betalingsMelding(_me: ApiMe) {
    const me = _me as ApiMe & {
        BetalingsMelding: null | string
    };

    if (!me.BetalingsMelding) {
        return;
    }

    const element = document
        .createElement("div");

    element.style.cssText = `
        width: 100%;
    `;

    element.innerHTML = `
        <div id="betalingsmelding">
            <div class="iconwrapper">
                <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="exclamation-triangle"
                    class="svg-inline--fa fa-exclamation-triangle fa-w-18 notificationicon"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                >
                    <path
                        fill="currentColor"
                        d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                    ></path>
                </svg>
            </div>
            <div class="bm-melding">${me.BetalingsMelding}</div>
            <div class="bm-kun-admin">Meldingen vises kun for administratorer</div>
        </div>
        <style>
            #betalingsmelding {
                position: relative;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                padding: 20px;
                background-color: #faebeb;
                border-bottom: 1px solid #a3292f1f;
            }

            @media print {
                #betalingsmelding {
                    display: none;
                }
            }

            #betalingsmelding > .iconwrapper > svg {
                color: #a3292f;
                font-size: 25px;
                width: 1.125em;
                vertical-align: -0.125em;
                margin-right: 20px;
            }

            #betalingsmelding > .bm-melding,
            #betalingsmelding > .bm-kun-admin {
                font-family: "Open Sans",Arial,sans-serif;
                color: black;
            }

            #betalingsmelding > .bm-melding {
                font-weight: 700;
                font-size: 16px;
            }

            #betalingsmelding > .bm-kun-admin {
                position: absolute;
                top: 0;
                right: 0;
                padding: 0px 6px;
                border-bottom-left-radius: 3px;
                background-color: #a3292f1f;
                font-size: 12px;
            }
        </style>
    `;

    const hovedcontainer = document
        .getElementById("hovedcontainer");

    if (hovedcontainer === null) {
        console
            .warn("Element with id 'hovedcontainer' not found!");

        return;
    }

    hovedcontainer
        .insertBefore(
            element,
            hovedcontainer.firstChild
        );
}

export default betalingsMelding;

export {
    betalingsMelding,
};
