import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, AlertColor, Theme, Tooltip, useMediaQuery } from '@mui/material';
import { ApiMe } from '../helpers/types';
import './TestDbPanel.less';

type Props = { minInfo?: ApiMe };

const TestDbPanel = ({ minInfo }: Props) => {
    const dbText = minInfo?.ErMalDatabase ? 'MALDATABASE' : 'TESTDATABASE';
    const dbColor: AlertColor = minInfo?.ErMalDatabase ? 'error' : 'warning';
    const dbBackgroundColor = minInfo?.ErMalDatabase ? 'rgba(255,0,0,0.1)' : '#fff3e6';
    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

    return (
        <Tooltip title={`${dbText} - ${minInfo?.FirmaNavn}`}>
            <Alert
                icon={<FontAwesomeIcon icon={faExclamationCircle} />}
                severity={dbColor}
                variant="outlined"
                sx={{
                    '& .MuiAlert-icon': {
                        marginRight: isMobile ? '0px' : '12px',
                    },
                    border: '1px solid #ff0000',
                    paddingTop: isMobile ? '0' : 'unset',
                    paddingBottom: isMobile ? '0' : 'unset',
                    cursor: 'pointer',
                    userSelect: 'none',
                    backgroundColor: dbBackgroundColor,
                }}
            >
                {!isMobile ? (
                    <>
                        <strong>{dbText}</strong> {!isTablet ? minInfo?.FirmaNavn : null}
                    </>
                ) : null}
            </Alert>
        </Tooltip>
    );
};

export default TestDbPanel;
