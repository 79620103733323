import { Varsling } from './types';

export const calculateNotificationAge = (notificationDateString: string): string => {
    const notificationDate = new Date(notificationDateString);
    const now = new Date();
    const ageInMillis = now.getTime() - notificationDate.getTime();
    const ageInSeconds = ageInMillis / 1000;

    if (ageInSeconds < 60) {
        return `${Math.floor(ageInSeconds)} sekunder siden`;
    } else if (ageInSeconds < 3600) {
        const ageInMinutes = Math.floor(ageInSeconds / 60);
        return `${ageInMinutes} ${ageInMinutes === 1 ? 'minutt' : 'minutter'} siden`;
    } else if (ageInSeconds < 86400) {
        const ageInHours = Math.floor(ageInSeconds / 3600);
        return `${ageInHours} ${ageInHours === 1 ? 'time' : 'timer'} siden`;
    } else {
        const ageInDays = Math.floor(ageInSeconds / 86400);
        return `${ageInDays} ${ageInDays === 1 ? 'dag' : 'dager'} siden`;
    }
};

export const createVarslingTooltip = (varsling: Varsling): string =>
    `${varsling?.Channel ? `${varsling.Channel} ${varsling.SubChannel ? `- ${varsling.SubChannel}` : ``}` : ``}`;
