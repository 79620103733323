import { UserAccessType } from '../components/KunngjoringsMelding/KunngjoringsMelding';

type Kunngjoring = {
    Id: string;
    NotificationKind: string;
    Message: string;
    Link: string;
    ShowInSandkasse: boolean;
    FromDate: string;
    ToDate: string;
    ShowToKunder: boolean;
    UserAccess: UserAccessType;
} & { closed: boolean };

export const useKunngjoringerSS = (): {
    getKunngjoringSS: (kunngjoringId: string, minInfoId: number) => Kunngjoring | null;
    setKunngjoringSS: (kunngjoringId: string, minInfoId: number, Kunngjoring: Kunngjoring) => void;
} => {
    const getKunngjoringSS: (kunngjoringId: string, minInfoId: number) => Kunngjoring | null = (
        kunngjoringId: string,
        minInfoId: number
    ) => {
        const kunngjoring: string | null = sessionStorage.getItem(`${minInfoId}-${kunngjoringId}`);
        if (kunngjoring) {
            return JSON.parse(kunngjoring);
        }
        return null;
    };

    const setKunngjoringSS: (kunngjoringId: string, minInfoId: number, kunngjoring: Kunngjoring) => void = (
        kunngjoringId: string,
        minInfoId: number,
        kunngjoring: Kunngjoring
    ) => {
        sessionStorage.setItem(`${minInfoId}-${kunngjoringId}`, JSON.stringify(kunngjoring));
    };

    return { getKunngjoringSS, setKunngjoringSS };
};
