import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons/faExclamationCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItem, ListItemAvatar, ListItemText, Typography } from '@mui/material';

type Props = {
    error: Error;
    resetErrorBoundary: () => void;
};

const ErrorBoundaryVarslingEntry = ({ error, resetErrorBoundary }: Props) => {
    return (
        <ListItem sx={{ padding: 0, borderBottom: 1, borderBottomColor: '#d3d3d3', backgroundColor: '#fff3e6' }}>
            <ListItemAvatar sx={{ padding: '15px' }}>
                <FontAwesomeIcon icon={faExclamationCircle} color="#ffaa4d" size="3x" />
            </ListItemAvatar>
            <ListItemText
                sx={{ padding: '0px 45px 0px 0px' }}
                primary={
                    <Typography
                        sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: '2',
                            WebkitBoxOrient: 'vertical',
                            margin: '1',
                        }}
                    >
                        <Typography>Noe gikk galt med lasting av varslingen</Typography>
                    </Typography>
                }
            />
        </ListItem>
    );
};

export default ErrorBoundaryVarslingEntry;
