import { createTheme, PaletteColorOptions, ThemeOptions } from '@mui/material';
declare module '@mui/material/styles' {
    interface BreakpointOverrides {
        mobile: true;
        tbClockInScreen: true;
    }

    interface PaletteOptions {
        celebration?: PaletteColorOptions;
    }
    interface Palette {
        celebration?: PaletteColorOptions;
    }
}
declare module '@mui/material/alert' {
    interface AlertPropsColorOverrides {
        celebration?: true;
    }
}

const muiTheme: ThemeOptions = {
    typography: {
        fontFamily: ['Open Sans', 'sans serif'].join(','),
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fbf8f4',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 'bold',
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    minWidth: '20rem',
                },
            },
        },
    },
    breakpoints: {
        values: {
            ...createTheme().breakpoints.values,
            mobile: 768,
            tbClockInScreen: 1366,
        },
    },
    palette: {
        primary: {
            main: '#3878c7',
            dark: '#2d609f',
            light: '#afc9e9',
            contrastText: '#ffffff',
        },
        secondary: {
            main: '#3d4e5c',
            dark: '#141a1f',
            light: '#668299',
            contrastText: '#ffffff',
        },
        success: {
            main: '#2d9f79',
            dark: '#22775b',
            light: '#9ce3cb',
            contrastText: '#ffffff',
        },
        warning: {
            main: '#ffaa4d',
            dark: '#ff901a',
            light: '#ffdab3',
            contrastText: '#ffffff',
        },
        error: {
            main: '#cd333b',
            dark: '#a3292f',
            light: '#e6999d',
            contrastText: '#ffffff',
        },
        celebration: {
            main: '#38afc7',
            dark: '#2d8c9f',
            light: '#ebf7f9',
            contrastText: '#000000',
        },
    },
};

export const muiMenuTheme = createTheme(muiTheme);
