import React from 'react';
import { faCheck, faExclamationCircle, faExclamationTriangle, faBirthdayCake } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Link, Typography } from '@mui/material';
import Alert, { alertClasses } from '@mui/material/Alert';
import { usePhrases } from '@tidsbanken/phrases';
import { useKunngjoringerSS } from '../../hooks/useKunngjoringerSessionStorage';
import { Kunngjoring, Kunngjoringer } from './KunngjoringsMelding';

type Props = {
    k: Kunngjoring;
    minInfoId: number;
    setKunngjoringer: React.Dispatch<React.SetStateAction<Kunngjoringer>>;
};

const NotificationWrapper = ({ k, minInfoId, setKunngjoringer }: Props) => {
    const p = usePhrases('meny');
    const { getKunngjoringSS, setKunngjoringSS } = useKunngjoringerSS();

    const kunngjoring = getKunngjoringSS(k.Id, minInfoId);
    const showActionLabelOnlyIfDefaultIsChangedOrNotEmpty = k.Link.length > 0 && k.Link !== 'https://';

    const borderStyle = {
        borderWidth: '2px',
        borderStyle: 'solid',
    };
    const celebrationSx = {
        ...borderStyle,
        borderColor: `${k.NotificationKind}.main`,
        color: 'celebration.contrastText',
        backgroundColor: 'celebration.light',
    };

    const severitySx = {
        ...borderStyle,
        borderColor: `${k.NotificationKind}.light`,
    };

    const severityOrCelebrationSx = k.NotificationKind === 'celebration' ? celebrationSx : severitySx;

    if (!kunngjoring?.closed) {
        return (
            <Alert
                severity={k.NotificationKind === 'celebration' ? undefined : k.NotificationKind}
                sx={{
                    placeContent: 'center',
                    placeItems: 'center',
                    [`.${alertClasses.action}`]: {
                        padding: '0',
                        paddingLeft: '16px',
                    },
                    ...severityOrCelebrationSx,
                }}
                icon={k.NotificationKind === 'celebration' ? <FontAwesomeIcon icon={faBirthdayCake} /> : undefined}
                color={k.NotificationKind === 'celebration' ? 'celebration' : undefined}
                iconMapping={{
                    error: <FontAwesomeIcon icon={faExclamationTriangle} />,
                    info: <FontAwesomeIcon icon={faExclamationCircle} />,
                    warning: <FontAwesomeIcon icon={faExclamationCircle} />,
                    success: <FontAwesomeIcon icon={faCheck} />,
                }}
                onClose={() => {
                    setKunngjoringSS(k.Id, minInfoId, { ...k, closed: true });
                    setKunngjoringer((prev) => {
                        if (prev) {
                            const filteredKunngjoring = prev.filter((prevK) => prevK.Id !== k.Id);
                            return [...filteredKunngjoring];
                        }
                        return prev;
                    });
                }}
            >
                <Box sx={{ display: 'grid', gridTemplateColumns: 'minmax(0, 1fr) 0.09fr', gap: '0.5rem' }}>
                    <Typography fontSize={'14px'}>{k.Message}</Typography>
                    <Box sx={{ placeSelf: 'center end' }}>
                        {showActionLabelOnlyIfDefaultIsChangedOrNotEmpty ? (
                            <Link href={k.Link} sx={{ textDecoration: 'none' }}>
                                <Typography noWrap fontWeight={600}>
                                    {p('read_more')}
                                </Typography>
                            </Link>
                        ) : null}
                    </Box>
                </Box>
            </Alert>
        );
    }
    return null;
};

export default NotificationWrapper;
