import * as React from 'react';
import './index.less';
import HelpDocument from './HelpDocument';
import HelpContactSchema from './HelpContactSchema';

export type SupportDokument = {
    DocHeadline: string;
    DocSubheadline: string;
    DocText: string;
    ID: number;
    Sokbar: boolean;
    VenstreBeskrivelse: string;
};

type HelpProps = {
    minInfo?: ApiMe;
    visHjelp: boolean;
};

type HelpState = {
    loaded: boolean;
    document?: SupportDokument;
};

export default class Help extends React.Component<HelpProps, HelpState> {
    private _lastLoadedArticle: string = '';

    constructor(props: HelpProps) {
        super(props);

        this.state = {
            loaded: false,
        };

        this.sendSupportRequest = this.sendSupportRequest.bind(this);
    }

    componentDidUpdate() {
        if (this.props.visHjelp) {
            this.fetchArticle();
        }
    }

    public fetchArticle() {
        // Replace ".asp" (any case) with ""
        // Replace "/" with "__"
        const app = "/" + window.location.pathname
            .slice(1)
            .replace(/\.[aA][sS][pP]\/?$/, "")
            .replace(/\//g, "__");

        if (this._lastLoadedArticle === app) {
            return;
        }

        this._lastLoadedArticle = app;

        fetch('https://supportapi.tidsbanken.net/api/SupportDokument' + app)
            .then((res) => res.json())
            .then((data) => {
                this.setState({
                    loaded: true,
                    document: data,
                });

                if (!data) {
                    // Don't do this anymore
                    // https://tidsbanken.atlassian.net/browse/DRFT-586
                    //
                    // this.sendSupportRequest();
                }
            })
            .catch(() => {
                this.setState({
                    loaded: true
                });

                // Don't do this anymore
                // https://tidsbanken.atlassian.net/browse/DRFT-586
                //
                // this.sendSupportRequest();
            });
    }

    sendSupportRequest() {

        let message = `
            Kundenummer: ${this.props.minInfo ? this.props.minInfo.FirmaId : 'Fant ikke firma-ID'} <br/><br/>
            Ops! En kunde har akkurat klikket hjelp, men fikk ikke opp noe support dokument på siden '
            ${window.location.href}
            . Men det er vel lett å fikse?
             <br/> <br/>
        `;

        const mail = {
            kundenr: this.props.minInfo ? this.props.minInfo.FirmaId : 'Klarte ikke å finne firmaID',
            to: 'support@tidsbanken.no',
            from: 'noreply@tidsbanken.net',
            html: message,
            subject: 'Feilmelding fra Tidsbanken@hjelp'
        };

        // The user is not aware of this happening, so don't bother handling success or error!
        // tslint:disable-next-line:max-line-length
        fetch('https://sendepost.tidsbanken.net/api/Send?code=gf5WobfbKVcmNvmJ5/PldB1OV/mkPKLvO75k3qiXaX12KziKRDsIFA==', {
            body: JSON.stringify(mail),
            method: 'POST',
            credentials: 'include'
        });
    }

    render() {
        return (
            this.props.visHjelp ? (
                <div className="HelpArticle menu-pad">
                    {!this.state.loaded
                        ? <div>Laster ...</div>
                        : (
                            <div>
                                {this.state.document ? (
                                    <HelpDocument
                                        document={this.state.document}
                                        minInfo={this.props.minInfo}
                                    />
                                )
                                    : (
                                        <div>
                                            <h3>Finner ingen hjelpetekst.</h3>
                                            <span>
                                                Kanskje du finner det ved å {' '}
                                                <a href="https://tidsbanken.net/support/default.asp" target="_blank">
                                                    søke i Supportsenteret
                                                </a>
                                            </span>
                                        </div>
                                    )}

                                <hr />
                                {/* <HelpContactSchema minInfo={this.props.minInfo} /> */}
                                <h2>Fant du ikke svaret på spørsmålet ditt?</h2>
                                <p>
                                    Send gjerne en henvendelse til <a href="mailto:support@tidsbanken.no">support@tidsbanken.no</a> så vil vårt supportteam gripe fatt i saken din 😊
                                </p>
                            </div>
                        )
                    }
                </div>
            ) : ''
        );
    }
}
