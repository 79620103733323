import { Badge, ListItem, ListItemAvatar, ListItemText, Skeleton } from '@mui/material';

const SkeletonVarslinger = () => {
    return (
        <ListItem sx={{ padding: 0, borderBottom: 1, borderBottomColor: '#d3d3d3' }}>
            <ListItemAvatar sx={{ padding: '15px' }}>
                <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    badgeContent={<Skeleton animation="wave" variant="circular" />}
                    color="primary"
                    sx={{
                        '& .MuiBadge-badge': {
                            backgroundColor: 'grey',
                        },
                    }}
                >
                    <Skeleton animation="wave" variant="circular" width={40} height={40} />
                </Badge>
            </ListItemAvatar>

            <ListItemText
                sx={{ padding: '0px 45px 0px 0px' }}
                primary={<Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />}
                secondary={<Skeleton animation="wave" height={10} width="60%" />}
            />
        </ListItem>
    );
};

export default SkeletonVarslinger;
