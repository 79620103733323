import * as React from 'react';
import { createPortal } from 'react-dom';
import { ApiMe } from '../../helpers/types';
import { useKunngjoringerSS } from '../../hooks/useKunngjoringerSessionStorage';
import KunngjoringerForAllAdministratorer from './KunngjoringerForAllAdministratorer';
import KunngjoringerForAllAvdelingsledereAndAdministratorer from './KunngjoringerForAllAvdelingsledereAndAdministratorer';
import KunngjoringerForAllBrukere from './KunngjoringerForAllBrukere';
import './KunngjoringsMelding.less';

export enum UserAccessType {
    Alle = 0,
    AdministratorOgAvdelingsleder = 1,
    Administrator = 2,
}

export type Kunngjoring = {
    Id: string;
    NotificationKind: `success` | `info` | `warning` | `error` | `celebration`;
    Message: string;
    Link: string;
    ShowInSandkasse: boolean;
    FromDate: string;
    ToDate: string;
    ShowToKunder: boolean;
    UserAccess: UserAccessType;
};
export type Kunngjoringer = Kunngjoring[] | undefined;

const kunngjoringsURL =
    window.location.hostname === `lokal.tidsbanken.net`
        ? `https://sandkasse.tidsbanken.net/kunngjoringer`
        : `/kunngjoringer`;

type Props = {
    minInfo?: ApiMe;
};

const KunngjoringsMelding = ({ minInfo }: Props) => {
    const [kunngjoringer, setKunngjoringer] = React.useState<Kunngjoringer>(undefined);
    const { getKunngjoringSS } = useKunngjoringerSS();
    const createKunngjoringsMeldingWrapper: HTMLDivElement = document.createElement('div');
    const hovedContainer: HTMLElement | null = document.getElementById('hovedcontainer');

    const hasKunngjoringerAndNotClosedInSS =
        (kunngjoringer &&
            kunngjoringer.filter((k) => {
                const kunngjoringFromSS = getKunngjoringSS(k.Id, minInfo?.Id ?? 0);
                if (kunngjoringFromSS?.closed) {
                    return false;
                }
                return true;
            }).length > 0) ??
        false;

    async function getKunngjoringer() {
        try {
            const res = await fetch(kunngjoringsURL, { mode: 'cors' });
            const allKunngjoringer: Kunngjoringer = await res.json();
            setKunngjoringer(allKunngjoringer);
            if (allKunngjoringer && minInfo) {
                allKunngjoringer.forEach((k) => {
                    const { getKunngjoringSS, setKunngjoringSS } = useKunngjoringerSS();
                    const kunngjoring = getKunngjoringSS(k.Id, minInfo?.Id);
                    if (!kunngjoring) {
                        setKunngjoringSS(k.Id, minInfo.Id, { ...k, closed: false });
                    }
                });
            }
        } catch (error) {
            console.error(error);
        }
    }

    React.useEffect((): (() => void) => {
        getKunngjoringer();
        if (hovedContainer && hasKunngjoringerAndNotClosedInSS) {
            hovedContainer.insertBefore(createKunngjoringsMeldingWrapper, hovedContainer.firstChild);
            createKunngjoringsMeldingWrapper.classList.add('KunngjoringsMelding');
        }
        return () => {
            createKunngjoringsMeldingWrapper.remove();
        };
    }, [hasKunngjoringerAndNotClosedInSS]);

    const KunngjoringsMeldingWrapper: Element | null = document.querySelector('.KunngjoringsMelding');

    if (!hovedContainer || !KunngjoringsMeldingWrapper) {
        return null;
    }

    return hasKunngjoringerAndNotClosedInSS
        ? createPortal(
              <>
                  <KunngjoringerForAllBrukere
                      kunngjoringer={kunngjoringer}
                      setKunngjoringer={setKunngjoringer}
                      minInfo={minInfo}
                  />
                  <KunngjoringerForAllAvdelingsledereAndAdministratorer
                      kunngjoringer={kunngjoringer}
                      setKunngjoringer={setKunngjoringer}
                      minInfo={minInfo}
                  />
                  <KunngjoringerForAllAdministratorer
                      kunngjoringer={kunngjoringer}
                      setKunngjoringer={setKunngjoringer}
                      minInfo={minInfo}
                  />
              </>,
              KunngjoringsMeldingWrapper
          )
        : null;
};

export default KunngjoringsMelding;
