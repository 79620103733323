import * as React from 'react';
import { useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { faEllipsisH } from '@fortawesome/pro-solid-svg-icons/faEllipsisH';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, List } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { Varsling } from '../../helpers/types';
import useCloseVarslingMenuOnClickOutside from '../../hooks/use-varslingClickOutside';
import useVarslinger from '../../hooks/use-Varslinger';
import ErrorBoundaryVarslingEntry from '../UI/ErrorboundaryVarslingEntry';
import SkeletonVarslinger from '../UI/SkeletonVarslinger';
import TbButton from '../UI/TbButton';
import VarslingMenyEmptyState from '../UI/VarslingMenyEmptystate';
import VarslingEntry from './VarslingEntry';
import VarslingOptionsMenu from './VarslingOptionsMenu';
import './VarslingMenu.less';

type Props = {
    isMenuOpen: boolean;
    toggleMenu: () => Promise<void>;
};

const VarslingMenu = ({ isMenuOpen, toggleMenu }: Props) => {
    const p = usePhrases('meny');

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isOptionMenuOpen, setIsOptionMenuOpen] = useState(false);

    const { varslinger, isLoadingVarslinger } = useVarslinger(isMenuOpen);
    const varslingMenuRef = useRef(null);
    useCloseVarslingMenuOnClickOutside(varslingMenuRef, toggleMenu);

    const handleToggleOptionsMenu = async (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        setIsOptionMenuOpen(!isOptionMenuOpen);
    };
    const PulldownKnapperHeight = 30;
    const varslingHeaderHeight = 37;
    const topMenyHeightMobile = 50;
    const getVarslingHeight = window.innerHeight - PulldownKnapperHeight - varslingHeaderHeight - topMenyHeightMobile;
    return (
        <Box>
            {isMenuOpen && (
                <Box
                    ref={varslingMenuRef}
                    className={'PulldownWrapperNotifications ' + (isMenuOpen ? 'synlig' : 'skjult')}
                >
                    <Box className={'PulldownInnhold'}>
                        <Box className="notification-heading" sx={{ paddingLeft: '0.5rem', paddingRight: '0.5rem' }}>
                            <h3 className="notification-heading-text">{p('notifications')}</h3>
                            <IconButton
                                className="notification-heading-button"
                                onClick={handleToggleOptionsMenu}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'white',
                                        color: '#29343d !important',
                                    },
                                    color: isOptionMenuOpen ? '#29343d !important' : 'unset',
                                    backgroundColor: isOptionMenuOpen ? 'white' : 'unset',
                                    height: '1.8rem',
                                    width: '1.8rem',
                                }}
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </IconButton>
                        </Box>

                        {isOptionMenuOpen && anchorEl !== null && (
                            <VarslingOptionsMenu
                                open={isOptionMenuOpen}
                                anchorEl={anchorEl}
                                toggleMenu={handleToggleOptionsMenu}
                            />
                        )}

                        <Box className="notification-wrapper">
                            <List sx={{ padding: 0, overflow: 'auto', maxHeight: getVarslingHeight }}>
                                {isLoadingVarslinger && (
                                    <>
                                        {[0, 0, 0].map((_, i) => (
                                            <SkeletonVarslinger key={i} />
                                        ))}
                                    </>
                                )}
                                {varslinger.length > 0 &&
                                    varslinger
                                        .map((varsling: Varsling) => (
                                            <ErrorBoundary
                                                key={varsling.Id}
                                                FallbackComponent={ErrorBoundaryVarslingEntry}
                                            >
                                                <VarslingEntry varsling={varsling} />
                                            </ErrorBoundary>
                                        ))}

                                <VarslingMenyEmptyState
                                    finishedLoadingVarslinger={!isLoadingVarslinger}
                                    showEmptyState={varslinger.length === 0}
                                />
                            </List>
                        </Box>

                        <div className="PulldownKnapper">
                            <TbButton className="PulldownKnapp" component="a" href="/varslinger/varslingsenter">
                                {p('see_all')}
                            </TbButton>

                            <TbButton className="PulldownKnapp" onClick={toggleMenu}>
                                {p('close')}
                            </TbButton>
                        </div>
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default VarslingMenu;
