
function slash(str: string) {
    if (str[0] === '/') {
        return str;
    }

    return '/' + str;
}

export default slash;

export {
    slash,
};
