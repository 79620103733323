
let prefixes = ' -webkit- -moz- -o- -ms-'.split(' ');

let query = prefixes
    .map(prefix => `(${prefix}touch-enabled)`)
    .join(',');

type CustomWindow = {
    DocumentTouch?: Function;
    ontouchstart?: Function;
} & Window;

let touch = () => {
    let w = window as CustomWindow;

    if ('ontouchstart' in w) {
        return true;
    }

    if (w.DocumentTouch && document instanceof w.DocumentTouch) {
        return true;
    }

    return w.matchMedia(query).matches;
};

export default touch;
