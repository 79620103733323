import React from 'react';
import { WithPhrases } from '@tidsbanken/phrases';
import { Aktivitet, Ansatte, Kunde, Oppgaver } from './core-utvalg';
import './SoekTreff.less';

export enum TreffType {
    pill_activity,
    pill_customer,
    pill_project,
    pill_employee,
}

interface ITreff {
    begrep: TreffType;
    id: number | string;
    navn: string;
}

interface ISoekTreffProps {
    sokestreng: string;
    soker: boolean;
}

interface ISoekTreffState {
    antallTreff: number;
    treffAktivitet: ITreff[];
    treffKunder: ITreff[];
    treffOppgaver: ITreff[];
    treffAnsatte: ITreff[];
}

const coreUrl = 'https://coresandkasse.tidsbanken.net/';

class SoekTreff extends React.Component<ISoekTreffProps, ISoekTreffState> {
    constructor(props: ISoekTreffProps) {
        super(props);
        this.state = {
            antallTreff: 0,
            treffAktivitet: [],
            treffKunder: [],
            treffOppgaver: [],
            treffAnsatte: [],
        };
    }

    componentDidMount() {
        if (this.props.sokestreng.length > 0) {
            this.soek(this.props.sokestreng);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISoekTreffProps) {
        if (nextProps.sokestreng.length > 0) {
            this.soek(nextProps.sokestreng);
        } else {
            this.setState({
                antallTreff: 0,
                treffAktivitet: [],
                treffKunder: [],
                treffOppgaver: [],
                treffAnsatte: [],
            });
        }
    }

    render() {
        return (
            <WithPhrases domain="meny">
                {(p) => (
                    <div className={'SoekTreff ' + (this.props.soker ? 'soker' : '')}>
                        <ul className="TreffListe">
                            {this.props.sokestreng &&
                                [
                                    ...this.state.treffAktivitet,
                                    ...this.state.treffAnsatte,
                                    ...this.state.treffOppgaver,
                                    ...this.state.treffKunder,
                                ].map((x) => {
                                    return (
                                        <a key={x.begrep + '-' + x.id} href={this.treffUrl(x)}>
                                            <li className={'treff-' + TreffType[x.begrep]}>
                                                <span className="begrep">{p(TreffType[x.begrep])}</span>
                                                <span className="id">
                                                    {this.boldify(x.id.toString(), this.props.sokestreng)}
                                                </span>
                                                <span className="navn">
                                                    {this.boldify(x.navn, this.props.sokestreng)}
                                                </span>
                                            </li>
                                        </a>
                                    );
                                })}
                        </ul>
                        <div className="sokestatus">
                            {this.state.antallTreff ? (
                                this.props.sokestreng ? (
                                    <span>{p('top_search_n_hits', `${this.state.antallTreff}`)}</span>
                                ) : (
                                    <span>{p('top_search_no_hits')}</span>
                                )
                            ) : (
                                <span>{p('top_search_no_hits')}</span>
                            )}
                        </div>
                    </div>
                )}
            </WithPhrases>
        );
    }

    private async soek(sokestreng: string) {
        const myInit = this.getInit();

        /*
        this.setState({
          treff: []
        });
        */

        (async () => {
            const arr: ITreff[] = [];
            // tslint:disable-next-line:max-line-length
            const ret = await fetch(
                coreUrl +
                    "Aktivitet?$top=10&$select=JobbNr,AktivitetsNavn&$filter=indexof(concat(concat(JobbNr, ' '), AktivitetsNavn),'" +
                    sokestreng +
                    "') gt -1",
                myInit
            )
                .then((res) => {
                    return res.json();
                })
                .then((parset) => {
                    return parset.value as Aktivitet[];
                });

            if (ret) {
                ret.map((x) => {
                    arr.push({
                        begrep: TreffType.pill_activity,
                        id: x.JobbNr,
                        navn: x.AktivitetsNavn,
                    });
                });
            }

            this.setState((ps) => ({
                treffAktivitet: arr,
                antallTreff: ps.antallTreff - ps.treffAktivitet.length + arr.length,
            }));
        })();

        (async () => {
            const arr: ITreff[] = [];
            // tslint:disable-next-line:max-line-length
            const ret = await fetch(
                coreUrl +
                    "Kunder?$top=10&$select=Kundenr,Navn&$filter=indexof(concat(concat(Kundenr, ' '), Navn),'" +
                    sokestreng +
                    "') gt -1",
                myInit
            )
                .then((res) => {
                    return res.json();
                })
                .then((parset) => {
                    return parset.value as Kunde[];
                });

            if (ret) {
                ret.map((x) => {
                    arr.push({
                        begrep: TreffType.pill_customer,
                        id: x.Kundenr,
                        navn: x.Navn,
                    });
                });
            }

            this.setState((ps) => ({
                treffKunder: arr,
                antallTreff: ps.antallTreff - ps.treffKunder.length + arr.length,
            }));
        })();

        (async () => {
            const arr: ITreff[] = [];
            // tslint:disable-next-line:max-line-length
            const ret = await fetch(
                coreUrl +
                    "Oppgaver?$top=10&$select=OppgaveNr,Oppgavenavn&$filter=indexof(concat(concat(OppgaveNr, ' '), Oppgavenavn),'" +
                    sokestreng +
                    "') gt -1",
                myInit
            )
                .then((res) => {
                    return res.json();
                })
                .then((parset) => {
                    return parset.value as Oppgaver[];
                });

            if (ret) {
                ret.map((x) => {
                    arr.push({
                        begrep: TreffType.pill_project,
                        id: x.OppgaveNr,
                        navn: x.Oppgavenavn,
                    });
                });
            }

            this.setState((ps) => ({
                treffOppgaver: arr,
                antallTreff: ps.antallTreff - ps.treffOppgaver.length + arr.length,
            }));
        })();

        (async () => {
            const arr: ITreff[] = [];
            // tslint:disable-next-line:max-line-length
            const ret = await fetch(
                coreUrl +
                    `Ansatte?$top=10&$select=AnsattnummerID,Fornavn,Etternavn&$filter=(indexof(concat(concat(Fornavn, ' '), Etternavn),'${sokestreng}') gt -1 or startswith(cast(AnsattnummerID, 'Edm.String'), '${sokestreng}')) and AnsattnummerID ne Mal`,
                myInit
            )
                .then((res) => {
                    return res.json();
                })
                .then((parset) => {
                    return parset.value as Ansatte[];
                });

            if (ret) {
                ret.map((x) => {
                    arr.push({
                        begrep: TreffType.pill_employee,
                        id: x.AnsattnummerID,
                        navn: x.Fornavn + ' ' + x.Etternavn,
                    });
                });
            }

            this.setState((ps) => ({
                treffAnsatte: arr,
                antallTreff: ps.antallTreff - ps.treffAnsatte.length + arr.length,
            }));
        })();
    }

    private getInit() {
        const myHeaders = new Headers();
        const cred: RequestCredentials = 'include';
        const requestmode: RequestMode = 'cors';
        const myInit: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            mode: requestmode,
            credentials: cred,
        };
        return myInit;
    }

    private boldify(tekst: string, fet: string) {
        const fetstart = tekst.toLowerCase().indexOf(fet.toLowerCase());
        if (fetstart === -1) {
            return tekst;
        } else {
            return (
                <span>
                    {tekst.substring(0, fetstart)}
                    <strong>{tekst.substring(fetstart, fetstart + fet.length)}</strong>
                    {tekst.substring(fetstart + fet.length)}
                </span>
            );
        }
    }

    private treffUrl(x: ITreff) {
        switch (x.begrep) {
            case TreffType.pill_activity:
                return '/akt.asp?id=' + x.id;
            case TreffType.pill_customer:
                return '/kartotek_kunder.asp?id=' + x.id;
            case TreffType.pill_project:
                return '/kartotek_prosjekter.asp?id=' + x.id;
            case TreffType.pill_employee:
                return '/kartotek_ansatte.asp?id=' + x.id;
            default:
                return '';
        }
    }
}

export default SoekTreff;
