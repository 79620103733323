export const stringToColour = (str: string) => {
    str = (str ?? '').trim().toUpperCase().replace(/\s/g, '');
    if (!str) return '#f0f2f2';
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    const saturation = 0.65; // Saturation factor (0-1)
    const lightness = 0.8; // Lightness factor (0-1)
    for (let i = 0; i < 3; i++) {
        const baseValue = (hash >> (i * 8)) & 0xff;
        const newValue = Math.floor(baseValue * saturation + lightness * 255 * (1 - saturation));
        colour += newValue.toString(16).padStart(2, '0').slice(-2);
    }
    return colour;
};
export const stringAvatar = (name: string) => ({
    sx: {
        bgcolor: stringToColour(name),
        width: '3.125rem',
        height: '3.125rem',
    },
});
