import { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { jwtDecode } from 'jwt-decode';
import { getNewEnvironment, memoize, singleItemFetch } from '../helpers/apiHelpers';
import { ApiMe } from '../helpers/types';
import TbButton from './UI/TbButton';

type DecodedJwt = {
    FirmaId: string;
    FirmaNavn: string;
    User: string;
    Kunde: string;
    UserId: string;
    Database: string;
    LoggetInnMedUserKey: string;
    LoggetInnMedKort: string;
    WebStartSide: string;
};

export const getApiMe = memoize((cookieGuid: string | null) => {
    const url = `${getNewEnvironment('me')}`;
    return singleItemFetch(url) as Promise<ApiMe>;
});

const extractCookieTbSignIn = () => {
    try {
        const cookieGuid = document.cookie.match(/\bTBSignIn=[^;]+/);
        return cookieGuid?.[0].split('=')[1] ?? '';
    } catch (error) {
        console.error('Error extracting cookie', error);
        return null;
    }
};

const extractCookie = () => {
    try {
        const cookieJwt = document.cookie.match(/\bTBSignInToken=[^;]+/);
        return cookieJwt?.[0].split('=')[1] ?? '';
    } catch (error) {
        console.error('Error extracting cookie', error);
        return null;
    }
};

const decodeJwtFromCookie = (cookie: string | null) => {
    try {
        return cookie ? jwtDecode<DecodedJwt>(cookie) : null;
    } catch (error) {
        console.error('Error decoding cookie', error);
        return null;
    }
};

type Props = {
    minInfo?: ApiMe;
};

const DbAlert = ({ minInfo }: Props) => {
    const [showAlert, setShowAlert] = useState(false);
    const [currentDecoded, setCurrentDecoded] = useState<DecodedJwt | ApiMe | null>(null);

    useEffect(() => {
        const callback = async () => {
            if ((document.hasFocus() || document.visibilityState === 'visible') && minInfo) {
                const cookie = extractCookie();
                const decoded = decodeJwtFromCookie(cookie);
                setCurrentDecoded(decoded);

                let currentFirmaId = decoded?.FirmaId;
                let currentFirmaNavn = decoded?.FirmaNavn;
                const minInfoFirmaId = minInfo?.FirmaId?.toString();
                const minInfoFirmaNavn = minInfo?.FirmaNavn;

                if (!decoded) {
                    if (minInfoFirmaId && minInfoFirmaNavn) {
                        const cookieGuid = extractCookieTbSignIn();
                        const res = await getApiMe(cookieGuid);
                        const { FirmaId, FirmaNavn } = res;
                        currentFirmaId = FirmaId ? FirmaId.toString() : undefined;
                        currentFirmaNavn = FirmaNavn;
                        setCurrentDecoded(res);
                    }
                }
                if (!currentFirmaId || !currentFirmaNavn || !minInfoFirmaId || !minInfoFirmaNavn) {
                    setShowAlert(false);
                } else {
                    if (currentFirmaId !== minInfoFirmaId || currentFirmaNavn !== minInfoFirmaNavn) {
                        console.warn('Database changed');
                        setShowAlert(true);
                    } else {
                        setShowAlert(false);
                    }
                }
            }
        };

        document.addEventListener('visibilitychange', callback);
        window.addEventListener('focus', callback);

        return () => {
            document.removeEventListener('visibilitychange', callback);
            window.removeEventListener('focus', callback);
        };
    }, [minInfo]);

    const handleReload = () => {
        window.location.reload();
    };

    return (
        <Dialog open={showAlert}>
            <DialogTitle sx={{ fontWeight: 'bold' }}>Siden er ikke gyldig 🤦‍♂️</DialogTitle>
            <DialogContent>
                Sist du brukte denne siden var du innlogget på <strong>{minInfo?.FirmaNavn}</strong>.
                <br />
                <br />
                Nå er du innlogget på <strong>{currentDecoded?.FirmaNavn}</strong>.
                <br />
                <br />
                <Typography sx={{ fontStyle: 'italic', fontSize: '12px' }} component={'span'}>
                    Hvis du ønsker å være innlogget på {minInfo?.FirmaNavn}, må du logge inn på denne i et nytt vindu i
                    nettleseren din. Deretter kan du gå tilbake til dette vinduet.
                </Typography>
            </DialogContent>
            <DialogActions>
                <TbButton onClick={handleReload}>Last siden på nytt</TbButton>
            </DialogActions>
        </Dialog>
    );
};

export default DbAlert;
