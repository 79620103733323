import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, IconButton } from '@mui/material';

const meldingerOnclick = () => {
    window.location.href = '/meldinger.asp';
};
type ToppMenyMeldingerProps = {
    unread?: number;
};

export const ToppMenyMeldinger = ({ unread }: ToppMenyMeldingerProps) => {
    return (
        <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent={unread}
            color="error"
            sx={{ fontSize: '1.25rem' }}
        >
            <IconButton color="secondary" id="basic-button" onClick={meldingerOnclick}>
                <FontAwesomeIcon icon={faEnvelope} color={'#29343D'} />
            </IconButton>
        </Badge>
    );
};
