import { Alert } from '@mui/material';

type Props = {
    error: Error;
    resetErrorBoundary: (...args: unknown[]) => void;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: Props) => {
    console.error(error);

    return (
        <Alert
            title={`Noe gikk galt. Klikk for å prøve på nytt.`}
            sx={{ background: 'none', cursor: 'pointer', alignItems: 'center', '& >.MuiAlert-icon': { mr: 0 } }}
            severity="error"
            onClick={resetErrorBoundary}
        ></Alert>
    );
};
