// Append script for embedding status
export const appendScript = (scriptToAppend: string, id?: string) => {
    const script = document.createElement('script');

    if (id) {
        script.id = id;
        script.defer = true;
    }

    script.src = scriptToAppend;
    script.async = true;
    document.body.appendChild(script);
};
