import { useRef, useState } from 'react';
import { faCartPlus } from '@fortawesome/pro-solid-svg-icons/faCartPlus';
import { faNetworkWired } from '@fortawesome/pro-solid-svg-icons/faNetworkWired';
import { faServer } from '@fortawesome/pro-solid-svg-icons/faServer';
import { faStore } from '@fortawesome/pro-solid-svg-icons/faStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { useOnClickOutside } from '../hooks/use-onClickOutside';
import MarketplaceElement from './MarketplaceElement';

const Marketplace = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const ref = useRef(null);
    useOnClickOutside(ref, () => setIsMenuOpen(false));

    const toggleMarket = (event: any) => {
        if (!isMenuOpen) {
            // Open the popover
            setAnchorEl(event.currentTarget);
        } else {
            // Close the popover
            setAnchorEl(null);
        }
        setIsMenuOpen(!isMenuOpen);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'marketplace-popover' : undefined;

    return (
        <Box
            className="MarketplaceWrapper"
            ref={ref}
            sx={{
                height: '100%',
                textAlign: 'center',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 10px',
                fontSize: '24px',
                display: 'flex',
            }}
        >
            <IconButton
                className="MarketplaceIcon"
                aria-describedby={id}
                onClick={toggleMarket}
                sx={{ color: '#29343d' }}
            >
                <FontAwesomeIcon icon={faStore} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={() => {
                    setIsMenuOpen(false);
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <Box p={2}>
                    <Typography variant="h6" gutterBottom>
                        Markedsplass
                    </Typography>
                    <div className="ElementsContainer">
                        <MarketplaceElement
                            elementfaIcon={faCartPlus}
                            elementName={'Tilleggsmoduler'}
                            elementDescription={'Finn de modulene som passer deres behov'}
                            elementLink={'https://lp.tidsbanken.no/produktoversikt'}
                        />
                        <MarketplaceElement
                            elementfaIcon={faNetworkWired}
                            elementName={'Integrasjoner'}
                            elementDescription={'Få systemene til å snakke sammen'}
                            elementLink={'https://lp.tidsbanken.no/integrasjoner'}
                        />
                        <MarketplaceElement
                            elementfaIcon={faServer}
                            elementName={'Utstyr og tjenester'}
                            elementDescription={'Se vårt utvalg av utstyr, kurs og opplæring'}
                            elementLink={'https://lp.tidsbanken.no/utstyr-og-tjenester'}
                        />
                    </div>
                </Box>
            </Popover>
        </Box>
    );
};

export default Marketplace;
