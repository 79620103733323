import { Avatar } from '@mui/material';
import { Varsling } from '../../helpers/types';

const VarslingAvatar = ({ varsling }: { varsling: Varsling }) => {
    const stringToColour = (str: string) => {
        str = (str ?? '').trim().toUpperCase().replace(/\s/g, '');
        if (!str) return '#f0f2f2';
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        const saturation = 0.65; // Saturation factor (0-1)
        const lightness = 0.8; // Lightness factor (0-1)
        for (let i = 0; i < 3; i++) {
            const baseValue = (hash >> (i * 8)) & 0xff;
            const newValue = Math.floor(baseValue * saturation + lightness * 255 * (1 - saturation));
            colour += newValue.toString(16).padStart(2, '0').slice(-2);
        }
        return colour;
    };

    const stringAvatar = (name: string) => {
        name = (name ?? '').trim();

        if (!name) {
            // Return a default avatar with some placeholder text or color.
            return {
                sx: {
                    bgcolor: '#f0f2f2', // Default background color
                },
            };
        }

        const names = name.split(' ');
        let initials = '';

        if (names.length >= 1) {
            initials += names[0][0];
        }

        if (names.length >= 2) {
            initials += names[names.length - 1][0];
        }

        if (!initials) {
            return {
                sx: {
                    bgcolor: '#f0f2f2',
                },
            };
        }

        return {
            sx: {
                bgcolor: stringToColour(name),
            },
            children: initials,
        };
    };

    const avatarProps = varsling.Sender.Picture
        ? { alt: varsling.Sender.Name, src: varsling.Sender.Picture }
        : stringAvatar(varsling.Sender.Name);

    return <Avatar {...avatarProps} />;
};

export default VarslingAvatar;
