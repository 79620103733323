import React from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ToppMenyItem.less';

interface IToppMenyItemProps {
    ikon: IconDefinition;
    tekst?: string;
    handling?: () => void;
    url?: string;
}

const ToppMenyItem = ({ url, ikon, tekst, handling }: IToppMenyItemProps) => {
    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (url) {
            handling?.();
        } else {
            e.preventDefault();
            handling?.();
        }
    };

    return (
        <a className="ToppMenyItemLinkWrapper" href={url || ''} onClick={handleClick} aria-label="ToppMenyItem">
            <div className="ToppMenyItem">
                <FontAwesomeIcon className={'fa fa-' + ikon} icon={ikon} aria-hidden="true" />
                {tekst && <span className="tekst">{tekst}</span>}
            </div>
        </a>
    );
};

export default ToppMenyItem;
