import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MarketplaceElement.less';

type Props = {
    elementfaIcon: IconDefinition;
    elementName: string;
    elementDescription: string;
    elementLink: string;
};

const MarketplaceElement = ({ elementDescription, elementLink, elementName, elementfaIcon }: Props) => {
    const openInNewTab = (url: string) => {
        if (url !== '') {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
            if (newWindow) newWindow.opener = null;
        }
    };

    return (
        <div className="MarketplaceElementWrapper" onClick={() => openInNewTab(elementLink)}>
            <FontAwesomeIcon className="ElementIcon" icon={elementfaIcon} size="2x" />
            <div className="ContentWrapper">
                <p className="ElementName">{elementName}</p>
                <p className="ElementDescription">{elementDescription}</p>
            </div>
        </div>
    );
};

export default MarketplaceElement;
