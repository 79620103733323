import React, { useRef } from 'react';
import { faBell } from '@fortawesome/pro-solid-svg-icons/faBell';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons/faCheckSquare';
import { faCog } from '@fortawesome/pro-solid-svg-icons/faCog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import { usePhrases } from '@tidsbanken/phrases';
import { mutate } from 'swr';
import { apiVarslingerVarslingerUrl, patchReadAll } from '../../helpers/apiHelpers';
import { Varsling } from '../../helpers/types';
import useCloseVarslingMenuOnClickOutside from '../../hooks/use-varslingClickOutside';

type Props = {
    open: boolean;
    anchorEl: null | HTMLElement;
    toggleMenu: (event: React.MouseEvent<HTMLElement>) => Promise<void>;
};

const VarslingOptionsMenu = ({ open, anchorEl, toggleMenu }: Props) => {
    const toggleOptionsMenuRef = useRef(null);
    const p = usePhrases('meny');

    useCloseVarslingMenuOnClickOutside(toggleOptionsMenuRef, toggleMenu);

    const handleClickReadAll = async () => {
        await mutate(
            apiVarslingerVarslingerUrl,
            async (data?: Varsling[]) => {
                const res = await patchReadAll();

                if (res.ok) {
                    return data?.map((varsling) => ({
                        ...varsling,
                        IsRead: true,
                    }));
                }
                return data;
            },
            {
                revalidate: false,
                rollbackOnError: true,
                optimisticData: (data) => {
                    return data?.map((varsling: Varsling) => ({
                        ...varsling,
                        IsRead: true,
                    }));
                },
            }
        );
    };

    return (
        <Box ref={toggleOptionsMenuRef}>
            <Menu open={open} onClose={toggleMenu} anchorEl={anchorEl} disableScrollLock={true}>
                <MenuItem onClick={handleClickReadAll}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faCheckSquare} />
                    </ListItemIcon>
                    <ListItemText sx={{ padding: '3px' }}>{p('mark_all_as_read')}</ListItemText>
                </MenuItem>
                <MenuItem component="a" href="/varslinger/innstillinger">
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faCog} />
                    </ListItemIcon>
                    <ListItemText sx={{ padding: '3px' }}>{p('notification_settings')}</ListItemText>
                </MenuItem>
                <MenuItem component="a" href="/varslinger/varslingsenter/">
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faBell} />
                    </ListItemIcon>
                    <ListItemText sx={{ padding: '3px' }}>{p('navigate_to_notification_centre')}</ListItemText>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default VarslingOptionsMenu;
