import * as React from 'react';
import './VisMenyKnapp.less';

interface IVisMenyKnappProps {
    toggleVenstreMeny: () => void;
    roter: boolean;
}

export default class VisMenyKnapp extends React.Component<IVisMenyKnappProps> {

    constructor(props: IVisMenyKnappProps) {
        super(props);
    }

    render() {
        return (
            <a
                onClick={() => { this.props.toggleVenstreMeny(); }}
            >
                <div className="VisMenyKnapp">
                    <div className="KnappBarer">
                        <span className={'KnappBar ' + (this.props.roter ? 'rotate-kb-1' : '')} />
                        <span className={'KnappBar ' + (this.props.roter ? 'rotate-kb-2' : '')} />
                    </div>
                </div>
            </a>
        );
    }
}
