// Login with key
export const setUserKeyAuthValues = () => 
{
    var params = document.location.search;
    var res = params.match(/\bkey=[^&]+/);
    if (res !== null) {
        var key = res[0].split("=")[1];
        var date = new Date();
        date.setMonth(date.getMonth() + 24);
        document.cookie = `Key=${key}; expires=${date.toUTCString()}; domain=.tidsbanken.net; path=/`;

        // Remove TBSignIn
        res = document.cookie.match(/\bTBSignIn=[^;]+/);
        if (res !== null) {
            var value = res[0].split("=")[1];
            date = new Date();
            date.setMonth(date.getMonth() - 12);
            document.cookie = `TBSignIn=${value}; expires=${date.toUTCString()}; domain=.tidsbanken.net; path=/`;
        }

        // Remove TBSignInToken
        res = document.cookie.match(/\bTBSignInToken=[^;]+/);
        if (res !== null) {
            var value = res[0].split("=")[1];
            date = new Date();
            date.setMonth(date.getMonth() - 12);
            document.cookie = `TBSignInToken=${value}; expires=${date.toUTCString()}; domain=.tidsbanken.net; path=/`;
        }
    }
};